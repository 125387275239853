import { FiTruck } from 'react-icons/fi';
import { RiShoppingBag2Line } from 'react-icons/ri';
import { FaRegFile } from 'react-icons/fa6';
import { theme } from '../../../theme';
import { ICategory } from '../../../types';
import styled from '@emotion/styled';

export const HomeDeliverySection = ({
  categories,
}: {
  categories?: { node: ICategory }[];
}) => {
  const deliveryOptions = [
    { icon: FiTruck, text: 'Pickup' },
    { icon: RiShoppingBag2Line, text: 'Delivery' },
    { icon: FaRegFile, text: 'Make a list' },
  ];

  const emptyCategories = !categories || categories.length < 1;

  return (
    <StyledDeliverySection emptyCategories={emptyCategories}>
      {deliveryOptions.map(({ icon: Icon, text }) => (
        <DeliveryButton key={text}>
          <IconStyled as={Icon} />
          <p>{text}</p>
        </DeliveryButton>
      ))}
    </StyledDeliverySection>
  );
};

const StyledDeliverySection = styled.div<{ emptyCategories: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 0 16px;
  margin-top: ${({ emptyCategories }) => (emptyCategories ? '90px' : '0')};
`;

const DeliveryButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  cursor: pointer;
  gap: 10px;
  height: 40px;
  padding: 0 14px;
  font-size: 16px;
  border-radius: 4px;
  background-color: ${theme.target.greyVeryLight};
  color: ${theme.target.white};

  p {
    color: ${theme.target.black};
    font-size: 14px;
    font-weight: 600;
  }
`;

const IconStyled = styled.span`
  font-size: 24px;
  color: ${theme.target.primary};
`;
