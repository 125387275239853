import { theme } from '../../../theme';
import { useAppContext } from '../../../context';
import { IProduct } from '../../../types';
import { CartProductCard } from './productCard/CartProductCard';
import styled from '@emotion/styled';

export const CartShippingSection = ({ handleCart }: any) => {
  const {
    value: { cart },
  } = useAppContext();

  return (
    <ComponentWrapper>
      {cart.map((product: IProduct) => (
        <CartProductCard
          key={product.id}
          product={product}
          handleCart={handleCart}
        />
      ))}
    </ComponentWrapper>
  );
};

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 16px 16px 16px;
  background-color: ${theme.target.white};
`;
