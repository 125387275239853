import banner from '../../../assets/images/homescreen/banner.png';
import styled from '@emotion/styled';

export const HomeBannerSection = () => (
  <BannerContainer>
    <BannerImage src={banner} alt="banner" />
  </BannerContainer>
);

const BannerContainer = styled.div`
  width: 100%;
  padding: 0px 16px;
`;

const BannerImage = styled.img`
  width: 100%;
  border-radius: 16px;
`;
