import { useState, useEffect } from 'react';
import { ThemeProvider } from '@emotion/react';
import { theme } from '../../theme/index';
import { BottomNavigationButton } from './BottomNavigationButton';
import { TargetIcon } from '../../assets/icons/TargetIcon';
import { useAppContext } from '../../context';
import styled from '@emotion/styled';
interface NavigationProps {
  navigation: (path: string) => void;
  storeId: string;
  harvestId: string;
}

export const bottomNavigationBarButtonData = [
  { iconName: 'home', label: 'Discover' },
  { iconName: 'essentials', label: 'Essentials' },
  { iconName: 'wallet', label: 'Wallet' },
  { iconName: 'cart', label: 'Cart' },
  { iconName: 'profile', label: 'Profile' },
];

export const BottomNavigationBar = ({
  navigation,
  storeId,
  harvestId,
}: NavigationProps) => {
  const currentPath = window.location.pathname;
  const slashCount = (currentPath.match(/\//g) || []).length;
  const initialIcon =
    slashCount === 2 ? 'home' : currentPath.includes('cart') ? 'cart' : null;
  const [clickedIcon, setClickedIcon] = useState<string | null>(initialIcon);
  const baseUrl = `/${storeId}/${harvestId}`;

  useEffect(() => {
    if (slashCount === 2) {
      setClickedIcon('home');
    } else if (
      currentPath.includes('cart') &&
      !currentPath.includes('cartadd')
    ) {
      setClickedIcon('cart');
    } else {
      setClickedIcon(null);
    }
  }, [currentPath]);

  const {
    value: { cart },
  } = useAppContext();

  const getTotalQuantity = () => {
    return cart.reduce((total: any, item: any) => total + item.amount, 0);
  };

  const handleNavigation = (iconName: string) => {
    if (['essentials', 'wallet', 'profile'].includes(iconName)) {
      return () => {};
    }

    const navigateMap: { [key: string]: string } = {
      home: baseUrl,
      essentials: `${baseUrl}/essentials`,
      wallet: `${baseUrl}/wallet`,
      cart: `${baseUrl}/cart`,
      profile: `${baseUrl}/profile`,
    };

    return () => {
      if (clickedIcon !== iconName) {
        setClickedIcon(iconName);
        navigation(navigateMap[iconName] || baseUrl);
      }
    };
  };

  return (
    <ThemeProvider theme={theme}>
      <BottomNavigationBarWrapper>
        <ItemsContainer>
          {bottomNavigationBarButtonData.map((button) => (
            <BottomNavigationButton
              key={button.label}
              iconName={button.iconName}
              label={button.label}
              active={clickedIcon === button.iconName}
              icon={
                <TargetIcon
                  scale={0.9}
                  active={clickedIcon === button.iconName}
                />
              }
              onClick={handleNavigation(button.iconName)}
              badge={
                (button.iconName === 'cart' && (
                  <Badge>{getTotalQuantity()}</Badge>
                )) ||
                undefined
              }
            />
          ))}
        </ItemsContainer>
      </BottomNavigationBarWrapper>
    </ThemeProvider>
  );
};

const BottomNavigationBarWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70px;
  z-index: 1000;
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.1);
  background-color: ${theme.target.white};
`;

const ItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 20px;
`;

const Badge = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -5px;
  right: -5px;
  font-size: 10px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  font-weight: 600;
  color: ${theme.target.white};
  background-color: ${theme.target.primary};
`;
