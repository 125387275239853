import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faBox, faTruck } from '@fortawesome/free-solid-svg-icons';
import { ShippingMethodStyled } from '../PdpCard/styles';
import { useConfiguration } from '../../hooks/useConfiguration';
import ShippingInfo from '../ShippingInfo';

interface ShippingMethodType {
  name: string;
  description: string;
  icon: JSX.Element;
}

interface ShippingMethodComponentProps {
  method: ShippingMethodType;
  isActive: boolean;
  onClick: () => void;
}

interface ShippingMethodsProps {
  shippingMethodSelected: string;
  setShippingMethodSelected: (method: string) => void;
  storeId: string;
  harvestId: string;
}

const ShippingMethods = ({
  shippingMethodSelected,
  setShippingMethodSelected,
  storeId,
  harvestId,
}: ShippingMethodsProps) => {
  const { Currency, ShipmentMethod } = useConfiguration({ storeId, harvestId });
  const icons: Record<string, JSX.Element> = {
    shipping: <FontAwesomeIcon icon={faTruck} />,
    pickup: <FontAwesomeIcon icon={faCar} />,
    delivery: <FontAwesomeIcon icon={faBox} />,
  };
  const [shippingMethodDescription, setShippingMethodDescription] =
    React.useState({});

  const descriptions: Record<string, string> = {
    shipping: 'Get it in 2-3 days',
    pickup: 'Get it in 2 hours',
    delivery: 'Get it in 3 hours',
  };

  const shipmentMethods: ShippingMethodType[] = React.useMemo(() => {
    if (!ShipmentMethod || ShipmentMethod.length === 0) {
      return [];
    }
    return ShipmentMethod.map((item: any) => ({
      name: item.Name,
      icon: icons[item.Name.toLowerCase()],
      description: descriptions[item.Name.toLowerCase()],
    }));
  }, [ShipmentMethod]);

  React.useEffect(() => {
    if (shipmentMethods.length > 0) {
      setShippingMethodSelected(shipmentMethods[0].name.toLowerCase());
      setShippingMethodDescription(shipmentMethods[0].name.toLowerCase());
    }
  }, [shipmentMethods]);

  React.useEffect(() => {
    setShippingMethodDescription(shipmentMethods?.[0]?.name.toLowerCase());
  }, [shipmentMethods]);

  if (shipmentMethods.length === 0) {
    return null;
  }

  return (
    <>
      <div className="shipping-methods">
        <div className="container">
          {shipmentMethods.map((method, key) => (
            <ShippingMethod
              key={key}
              isActive={shippingMethodSelected === method.name.toLowerCase()}
              method={method}
              onClick={() =>
                setShippingMethodSelected(method.name.toLowerCase())
              }
            />
          ))}
        </div>
      </div>
      {shipmentMethods.length > 0 && (
        <ShippingInfo shippingMethodSelected={shippingMethodSelected} />
      )}
    </>
  );
};

const ShippingMethod = ({
  method,
  isActive,
  onClick,
}: ShippingMethodComponentProps) => {
  return (
    <ShippingMethodStyled onClick={onClick} isActive={isActive}>
      <div className="icon">{method.icon}</div>
      <div className="name">{method.name}</div>
      <div className="description">{method.description}</div>
    </ShippingMethodStyled>
  );
};

export default ShippingMethods;
