import { SvgIconProps } from '@mui/material';

export const ClipboardIcon = (props: SvgIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 512 512"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M296.313 36.109c1.832 4.635 2.803 9.809 2.803 15.091 0 7.869-2.264 15.198-5.928 21.558h54.434c5.928 0 10.779 4.851 10.779 10.779v26.947H153.6V83.537c0-5.928 4.851-10.779 10.779-10.779h54.434c-3.665-6.36-5.928-13.689-5.928-21.558 0-5.282.97-10.456 2.803-15.091C221.723 19.725 237.46 8.084 256 8.084s34.277 11.641 40.313 28.025z"
      style={{
        fill: '#ffd248',
      }}
    />
    <path
      d="M358.4 94.316v16.168H153.6V94.316h-43.116v377.263h291.032V94.316z"
      style={{
        fill: '#e7eced',
      }}
    />
    <path
      d="m299.116 35.032-2.803 1.078c1.832 4.635 2.803 9.809 2.803 15.091 0 7.869-2.264 15.198-5.928 21.558h54.434c5.928 0 10.779 4.851 10.779 10.779v10.779h43.116V471.58H110.484V94.316H153.6V83.537c0-5.928 4.851-10.779 10.779-10.779h54.434c-3.665-6.36-5.928-13.689-5.928-21.558 0-5.282.97-10.456 2.803-15.091l-2.803-1.078H72.758v468.884h366.484V35.032H299.116z"
      style={{
        fill: '#546a79',
      }}
    />
    <path d="M301.027 26.947C292.259 10.59 274.948 0 256 0s-36.259 10.59-45.027 26.947H64.674V512h382.653V26.947h-146.3zm-75.21 41.774c-3.216-5.582-4.848-11.477-4.848-17.521 0-4.226.774-8.417 2.237-12.12l.067-.177c5.011-13.598 18.163-22.735 32.728-22.735 14.566 0 27.717 9.137 32.728 22.736l.067.177c1.463 3.701 2.237 7.892 2.237 12.119 0 6.044-1.632 11.939-4.848 17.521l-6.986 12.121h68.424c1.461 0 2.695 1.234 2.695 2.695V102.4H161.684V83.537c0-1.461 1.234-2.695 2.695-2.695h68.424l-6.986-12.121zm140.667 49.847V102.4h26.947v361.095H118.568V102.4h26.947v16.168h220.969zm64.674 377.264H80.842V43.116h124.621a50.74 50.74 0 0 0-.663 8.084c0 4.519.64 9.052 1.886 13.474h-42.307c-10.401 0-18.863 8.463-18.863 18.863v2.695H102.4v393.432h307.2V86.232h-43.116v-2.695c0-10.401-8.463-18.863-18.863-18.863h-42.307A49.697 49.697 0 0 0 307.2 51.2a50.74 50.74 0 0 0-.663-8.084h124.621v452.716z" />
    <path d="M245.221 43.116h21.558v16.168h-21.558zM142.821 161.684h32.337v16.168h-32.337zM196.716 161.684h172.463v16.168H196.716zM142.821 269.474h32.337v16.168h-32.337zM196.716 269.474h172.463v16.168H196.716zM142.821 377.263h32.337v16.168h-32.337zM196.716 377.263h172.463v16.168H196.716zM196.716 194.021h64.674v16.168h-64.674zM196.716 301.811h64.674v16.168h-64.674zM196.716 409.6h64.674v16.168h-64.674z" />
  </svg>
);
