import React from 'react';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import { Rating, Stack } from '@mui/material';
import { AddToCartButton } from '../AddToCartButton';
import { Link, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { theme } from '../../theme';
import { IProduct } from '../../types';
import { useConfiguration } from '../../hooks/useConfiguration';

interface IAdvBannerItem {
  product: IProduct;
  onClick: string;
  url: string;
}
export const AdvBannerItem = ({ product, onClick, url }: IAdvBannerItem) => {
  const { storeId = '', harvestId = '' } = useParams();
  const { Currency } = useConfiguration({
    storeId,
    harvestId,
  });
  const currency = Currency ? Currency : '$';
  const price =
    product?.Price !== 0
      ? product?.Price?.toFixed(2)
      : product?.RegularPrice?.toFixed(2);
  return (
    <LinkStyled to={onClick}>
      <AdvBannerItemStyled>
        <img
          src={`${imgSrcByEnv()}${product?.Gallery[0]?.image?.fullpath}`}
          alt="banner"
          style={{
            objectFit: 'fill',
            height: '150px',
            width: '130px',
            padding: '16px',
          }}
        />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: '4px 8px 4px 4px',
            width: '140px',
          }}
        >
          <p style={{ width: '100%', fontSize: '12px', fontWeight: 600 }}>
            {product.Name.slice(0, 50)}
          </p>
          <PriceContainer>
            {product.Price && product.Price !== 0 ? (
              <p className="price">
                Price: {currency}
                {price}
                <span className="oldPrice">
                  {' '}
                  (<s>${product.RegularPrice.toFixed(2)}</s>)
                </span>
              </p>
            ) : (
              <p className="price">
                <sup>{currency}</sup>
                {price}
              </p>
            )}
            {/* <span className="pricePerUnit">{pricePerUnit}</span> */}
          </PriceContainer>
          {product.RROverall && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <p style={{ paddingRight: '4px' }}>Rate: {product.RROverall}</p>
              <Stack spacing={1}>
                <Rating value={product.RROverall} precision={0.5} readOnly />
              </Stack>
            </div>
          )}

          <AddToCartButton product={product} url={url} />
        </div>
      </AdvBannerItemStyled>
    </LinkStyled>
  );
};

const LinkStyled = styled(Link)`
  text-decoration: none;
  color: ${theme.target.black};
`;
const AdvBannerItemStyled = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding-right: '8px';
  margin-right: '8px';
  border-right: '1px solid #f5f5f5';
`;
const PriceContainer = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: nowrap;
  width: 100%;
  margin-top: 5px;
  line-height: 17px;

  .price {
    font-size: 12px;
    .oldPrice {
      font-size: 10px;
      color: gray;
    }
  }

  .pricePerUnit {
    margin-left: 2px;
    font-size: 12px;
    color: ${theme.amazon.greyDark};
  }
  .simpleProduct {
    font-size: 22px;
    color: ${theme.amazon.red};
  }
`;
