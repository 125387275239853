import styled from "@emotion/styled";
import { theme } from "../../theme";

export const VariantContainer = styled.div`
  margin-top: 10px;
  
  & > p {
    margin: 10px 0;
  }

  .attribute-value {
    font-weight: 600;
    font-size: 14px;
    color: ${theme.target.greyDark};
  }

  & > .variants-inner-container {
    & > p {
      font-weight: 600;
      font-size: 16px;
      margin: 10px 0;
    }
  }

  .variants {
    display: flex;
    gap: 16px;
    max-width: 312px;
    overflow-x: auto;
    padding: 10px;

    @media (max-width: 768px) {
      max-width: 100%;
    }

    .active {
      border: 2px solid green;
    }

    .variant-label {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 6px 12px;

    }

    & > div {
      border: 1px solid grey;
      border-radius: 6px;
      display: flex;
      align-items: center;
      transition: background-color 0.3s; 

      &:hover {
        background-color: #f0f0f0;
      }

      img {
        object-fit: contain;
        display: block;
      }

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
      }

      span {
        font-size: 14px;
        font-weight: 500;
      }

      .variant-price {
        font-weight: 700;
      }
    }
  }
`;
