import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_PRODUCT_CATEGORY_BY_TERM } from '../../queries';
import { actions } from '../../context/actions';
import { useAppContext } from '../../context';
import { ModalTypes } from '../Modals/types';
import { Chip } from '../Chip';
import { IProduct } from '../../types';
import { useFilters } from './useFilters';
import {
  FiltersStyled,
  Header,
  PopularFiltersStyled,
  ChipsContainer,
} from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import 'swiper/css';
import { ReactSVG } from 'react-svg';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import { contentSquarePageEvent } from '../../helpers';
import { useConfiguration } from '../../hooks/useConfiguration';
import { GET_LOCALIZED } from '../../queries';
import { ChipWithImage } from '../ChipWithImage';
import styled from '@emotion/styled';

export const PopularFilters = ({
  products,
  categories,
  brands,
  productsBeforeFiltering,
  categoryName,
}: {
  products: IProduct[];
  productsBeforeFiltering: IProduct[];
  categories: any;
  brands: string[];
  categoryName?: any;
}) => {
  const {
    value: { productsSorting, searchFilters },
    dispatch,
  } = useAppContext();
  const {
    isPriceFilter,
    isBrandsFilter,
    isCategoriesFilter,
    isSpecialOffersFilter,
    isCustomFilter,
    handleFiltersVisibility,
  } = useFilters({
    products,
    productsBeforeFiltering,
    categoryName,
  });

  const navigate = useNavigate();

  const { storeId = '', termId, harvestId = '' } = useParams();

  const { LanguageVersion, FilterType } = useConfiguration({ storeId, harvestId });

  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });
  const { getLocalization } = translation;
  const { data } = useQuery(
    GET_PRODUCT_CATEGORY_BY_TERM({
      termId: termId?.split('__')[0],
    })
  );

  useConfiguration({
    storeId,
    harvestId,
  });

  const brandFilter = searchFilters.find(
    (filter: any) => filter.name === 'Brand'
  );
  const ratingFilter = searchFilters.find(
    (filter: any) => filter.name === 'Rating'
  );

  const variantsFilter = searchFilters.find(
    (filter: any) => filter.name === 'Variants'
  );

  const specialOffersFilter = searchFilters.find(
    (filter: any) => filter.name === 'Special Offers'
  );
  const FILTERS_OPTIONS = {
    PRICE: 'Price',
    RATING: 'Rating',
    VARIANTS: 'Variants',
    SPECIAL_OFFERS: 'Special Offers',
    CATEGORIES: 'Categories',
    BRAND: 'Brand',
  };
  const edges = data?.getEPogTermListing?.edges;

  let filters = edges.map((edge: any) => edge?.node?.PillFilters).flat();
  let filtersWithImage = edges
    .map((edge: any) => edge?.node?.PillFiltersWithImage)
    .flat();

  return (
    <FiltersStyled>
      <Header>{getLocalization.PopularFilters}</Header>
      <PopularFiltersStyled isLessThanSixItems={categories?.length}>
        {categories?.map((category: any, index: number) =>
          category?.TermImage?.fullpath ? (
            <div
              className="filter"
              key={index}
              onClick={() =>
                navigate(
                  `/${storeId}/${harvestId}/category/${
                    category.id
                  }__${category.key.replaceAll(' ', '_')}`
                )
              }
            >
              <img
                src={
                  category?.TermImage?.fullpath
                    ? `${imgSrcByEnv()}${category?.TermImage?.fullpath}`
                    : '/files/generic/img/square.svg'
                }
                alt="carousel"
              />
              <p> {category?.key}</p>
            </div>
          ) : (
            ''
          )
        )}
      </PopularFiltersStyled>
      {products.length || (!products?.length && searchFilters?.length) ? (
        filters.length <= 1 ? (
          <ChipsContainer>
            <Chip
              title={getLocalization.AllFilters}
              addinitonalIcon={
                <ReactSVG src="/files/generic/img/allFilters.svg" />
              }
              onClick={() => {
                handleFiltersVisibility();
                contentSquarePageEvent('allFilters');
              }}
              isExpand={false}
              background="#ffffff"
            />

            <Chip
              title={getLocalization.SortBy}
              addinitonalIcon={<ReactSVG src="/files/generic/img/sortBy.svg" />}
              colors={productsSorting.length ? 'dark' : 'light'}
              onClick={() => {
                contentSquarePageEvent('sortByFilter');
                dispatch({
                  type: actions.TOGGLE_MODAL,
                  payload: {
                    isModal: true,
                    modalType: ModalTypes.sorting,
                  },
                });
              }}
              isExpand={false}
              background="#ffffff"
            />
            {FilterType !== 'All' && FilterType !== 'Plain' && FilterType !== 'Image' && 
            <>
            <Chip
              title={getLocalization.Price}
              colors={isPriceFilter ? 'dark' : 'light'}
              onClick={() => {
                contentSquarePageEvent('priceFilter');
                dispatch({
                  type: actions.TOGGLE_MODAL,
                  payload: {
                    isModal: true,
                    modalType: ModalTypes.priceRange,
                    modalContent: { productsBeforeFiltering },
                  },
                });
              }}
              isExpand={false}
              background="#ffffff"
            />
            <Chip
              title={getLocalization.Rating}
              colors={ratingFilter?.value ? 'dark' : 'light'}
              onClick={() => {
                contentSquarePageEvent('ratingFilter');
                dispatch({
                  type: actions.TOGGLE_MODAL,
                  payload: {
                    isModal: true,
                    modalType: ModalTypes.rating,
                  },
                });
              }}
              isExpand={false}
              background="#ffffff"
            />
            <Chip
              title={getLocalization.Variants}
              colors={variantsFilter?.value ? 'dark' : 'light'}
              onClick={() => {
                contentSquarePageEvent('variantFilter');
                dispatch({
                  type: actions.TOGGLE_MODAL,
                  payload: {
                    isModal: true,
                    modalType: ModalTypes.variants,
                  },
                });
              }}
              isExpand={false}
              background="#ffffff"
            />

            <Chip
              title={getLocalization.Categories}
              onClick={() => {
                contentSquarePageEvent('categoriesFilter');
                dispatch({
                  type: actions.TOGGLE_MODAL,
                  payload: {
                    isModal: true,
                    modalType: ModalTypes.categories,
                    modalContent: { productsBeforeFiltering },
                  },
                });
              }}
              colors={isCategoriesFilter ? 'dark' : 'light'}
              isExpand={false}
              background="#ffffff"
            />

            <Chip
              title={getLocalization.SpecialOffers}
              colors={isSpecialOffersFilter ? 'dark' : 'light'}
              onClick={() => {
                contentSquarePageEvent('specialOffersFilter');
                dispatch({
                  type: actions.TOGGLE_MODAL,
                  payload: {
                    isModal: true,
                    modalType: ModalTypes.specialOffers,
                    modalContent: { productsBeforeFiltering },
                  },
                });
              }}
              isExpand={false}
              background="#ffffff"
            />

            <Chip
              title={getLocalization.Brand}
              onClick={() => {
                contentSquarePageEvent('brandSelectFilter');
                dispatch({
                  type: actions.TOGGLE_MODAL,
                  payload: {
                    isModal: true,
                    modalType: ModalTypes.brands,
                    modalContent: { productsBeforeFiltering },
                  },
                });
              }}
              colors={isBrandsFilter ? 'dark' : 'light'}
              isExpand={false}
              background="#ffffff"
            />

            {brands?.length > 1 &&
              brands?.map((brand: string) => {
                const isChecked = brandFilter?.brands[brand] || false;
                return (
                  <Chip
                    key={brand}
                    title={brand}
                    onClick={() => {
                      contentSquarePageEvent('brandFilter_' + brand);
                      dispatch({
                        type: actions.ADD_SEARCH_FILTER,
                        payload: {
                          name: 'Brand',
                          brands: {
                            ...brandFilter?.brands,
                            [brand]: !isChecked,
                          },
                        },
                      });
                    }}
                    colors={isChecked ? 'dark' : 'light'}
                    isExpand={false}
                    background="#ffffff"
                  />
                );
              })}
              </>}
          </ChipsContainer>
        ) : ((FilterType === 'All' || FilterType === 'Plain') && 
          <ChipsContainer>
            <Chip
              title={getLocalization.AllFilters}
              addinitonalIcon={
                <ReactSVG src="/files/generic/img/allFilters.svg" />
              }
              onClick={() => {
                handleFiltersVisibility();
                contentSquarePageEvent('allFilters');
              }}
              isExpand={false}
              background="#DBDBDB"
            />

            <Chip
              title={getLocalization.SortBy}
              addinitonalIcon={<ReactSVG src="/files/generic/img/sortBy.svg" />}
              colors={productsSorting.length ? 'dark' : 'light'}
              onClick={() => {
                contentSquarePageEvent('sortByFilter');
                dispatch({
                  type: actions.TOGGLE_MODAL,
                  payload: {
                    isModal: true,
                    modalType: ModalTypes.sorting,
                  },
                });
              }}
              isExpand={false}
              background="#DBDBDB"
            />
            {Object.values(filters).map((value, index: number) => {
              if (filters[index].Name === 'Price') {
                return (
                  <Chip
                    title={getLocalization.Price}
                    colors={isPriceFilter ? 'dark' : 'light'}
                    onClick={() => {
                      contentSquarePageEvent('priceFilter');
                      dispatch({
                        type: actions.TOGGLE_MODAL,
                        payload: {
                          isModal: true,
                          modalType: ModalTypes.priceRange,
                          modalContent: { productsBeforeFiltering },
                        },
                      });
                    }}
                    isExpand={false}
                    background="#ffffff"
                  />
                );
              }
              if (filters[index].Name === 'Rating') {
                return (
                  <Chip
                    title={getLocalization.Rating}
                    colors={ratingFilter?.value ? 'dark' : 'light'}
                    onClick={() => {
                      contentSquarePageEvent('ratingFilter');
                      dispatch({
                        type: actions.TOGGLE_MODAL,
                        payload: {
                          isModal: true,
                          modalType: ModalTypes.rating,
                        },
                      });
                    }}
                    isExpand={false}
                    background="#ffffff"
                  />
                );
              }
              if (filters[index].Name === 'Variants') {
                return (
                  <Chip
                    title={getLocalization.Variants}
                    colors={variantsFilter?.value ? 'dark' : 'light'}
                    onClick={() => {
                      contentSquarePageEvent('variantFilter');
                      dispatch({
                        type: actions.TOGGLE_MODAL,
                        payload: {
                          isModal: true,
                          modalType: ModalTypes.variants,
                        },
                      });
                    }}
                    isExpand={false}
                    background="#ffffff"
                  />
                );
              }
              if (filters[index].Name === 'Categories') {
                return (
                  <Chip
                    title={getLocalization.Categories}
                    onClick={() => {
                      contentSquarePageEvent('categoriesFilter');
                      dispatch({
                        type: actions.TOGGLE_MODAL,
                        payload: {
                          isModal: true,
                          modalType: ModalTypes.categories,
                          modalContent: { productsBeforeFiltering },
                        },
                      });
                    }}
                    colors={isCategoriesFilter ? 'dark' : 'light'}
                    isExpand={false}
                    background="#ffffff"
                  />
                );
              }
              if (filters[index].Name === 'Special Offers') {
                return (
                  <Chip
                    title={getLocalization.SpecialOffers}
                    colors={isSpecialOffersFilter ? 'dark' : 'light'}
                    onClick={() => {
                      contentSquarePageEvent('specialOffersFilter');
                      dispatch({
                        type: actions.TOGGLE_MODAL,
                        payload: {
                          isModal: true,
                          modalType: ModalTypes.specialOffers,
                          modalContent: { productsBeforeFiltering },
                        },
                      });
                    }}
                    isExpand={false}
                    background="#ffffff"
                  />
                );
              }
              if (filters[index].Name === 'Brand') {
                return (
                  <Chip
                    title={getLocalization.Brand}
                    onClick={() => {
                      contentSquarePageEvent('brandSelectFilter');
                      dispatch({
                        type: actions.TOGGLE_MODAL,
                        payload: {
                          isModal: true,
                          modalType: ModalTypes.brands,
                          modalContent: { productsBeforeFiltering },
                        },
                      });
                    }}
                    colors={isBrandsFilter ? 'dark' : 'light'}
                    isExpand={false}
                    background="#ffffff"
                  />
                );
              }
            })}
          </ChipsContainer>
        )
      ) : (
        ''
      )}
      {(FilterType === 'All' || FilterType === 'Image')
        && (products.length || (!products?.length && searchFilters?.length))
        && filtersWithImage.length > 1 && (
        <ChipWithImageContainerStyled>
          {Object.values(filtersWithImage).map((item, index: number) => {
            const brandName = filtersWithImage[index].Name;
            if (brandName === FILTERS_OPTIONS.PRICE)
              return (
                <ChipWithImage
                  key={brandName}
                  title={getLocalization.Price}
                  image={
                    imgSrcByEnv() + filtersWithImage[index].Thumbnail?.fullpath
                  }
                  onClick={() => {
                    contentSquarePageEvent('priceFilter');
                    dispatch({
                      type: actions.TOGGLE_MODAL,
                      payload: {
                        isModal: true,
                        modalType: ModalTypes.priceRange,
                        modalContent: { productsBeforeFiltering },
                      },
                    });
                  }}
                  colors={isPriceFilter ? 'dark' : 'light'}
                  isExpand={false}
                  background="#ffffff"
                />
              );
            if (brandName === FILTERS_OPTIONS.RATING)
              return (
                <ChipWithImage
                  key={brandName}
                  title={getLocalization.Rating}
                  image={
                    imgSrcByEnv() + filtersWithImage[index].Thumbnail?.fullpath
                  }
                  onClick={() => {
                    contentSquarePageEvent('ratingFilter');
                    dispatch({
                      type: actions.TOGGLE_MODAL,
                      payload: {
                        isModal: true,
                        modalType: ModalTypes.rating,
                      },
                    });
                  }}
                  colors={ratingFilter?.value ? 'dark' : 'light'}
                  isExpand={false}
                  background="#ffffff"
                />
              );
            if (brandName === FILTERS_OPTIONS.VARIANTS)
              return (
                <ChipWithImage
                  key={brandName}
                  title={getLocalization.Variants}
                  image={
                    imgSrcByEnv() + filtersWithImage[index].Thumbnail?.fullpath
                  }
                  onClick={() => {
                    contentSquarePageEvent('variantFilter');
                    dispatch({
                      type: actions.TOGGLE_MODAL,
                      payload: {
                        isModal: true,
                        modalType: ModalTypes.variants,
                      },
                    });
                  }}
                  colors={variantsFilter?.value ? 'dark' : 'light'}
                  isExpand={false}
                  background="#ffffff"
                />
              );
            if (brandName === FILTERS_OPTIONS.CATEGORIES)
              return (
                <ChipWithImage
                  key={brandName}
                  title={getLocalization.Categories}
                  image={
                    imgSrcByEnv() + filtersWithImage[index].Thumbnail?.fullpath
                  }
                  onClick={() => {
                    contentSquarePageEvent('categoriesFilter');
                    dispatch({
                      type: actions.TOGGLE_MODAL,
                      payload: {
                        isModal: true,
                        modalType: ModalTypes.categories,
                        modalContent: { productsBeforeFiltering },
                      },
                    });
                  }}
                  colors={isCategoriesFilter ? 'dark' : 'light'}
                  isExpand={false}
                  background="#ffffff"
                />
              );
            if (brandName === FILTERS_OPTIONS.BRAND)
              return (
                <ChipWithImage
                  key={brandName}
                  title={getLocalization.Brand}
                  image={
                    imgSrcByEnv() + filtersWithImage[index].Thumbnail?.fullpath
                  }
                  onClick={() => {
                    contentSquarePageEvent('brandSelectFilter');
                    dispatch({
                      type: actions.TOGGLE_MODAL,
                      payload: {
                        isModal: true,
                        modalType: ModalTypes.brands,
                        modalContent: { productsBeforeFiltering },
                      },
                    });
                  }}
                  colors={isBrandsFilter ? 'dark' : 'light'}
                  isExpand={false}
                  background="#ffffff"
                />
              );
            if (brandName === FILTERS_OPTIONS.SPECIAL_OFFERS)
              return (
                <ChipWithImage
                  key={brandName}
                  title={getLocalization.SpecialOffers}
                  image={
                    imgSrcByEnv() + filtersWithImage[index].Thumbnail?.fullpath
                  }
                  onClick={() => {
                    contentSquarePageEvent('specialOffersFilter');
                    dispatch({
                      type: actions.TOGGLE_MODAL,
                      payload: {
                        isModal: true,
                        modalType: ModalTypes.specialOffers,
                        modalContent: { productsBeforeFiltering },
                      },
                    });
                  }}
                  isExpand={false}
                  background="#ffffff"
                  colors={isSpecialOffersFilter ? 'dark' : 'light'}
              />
              );
            else {
              // const CorrectBrandName =
              //   brandName === 'Aussie' ? 'AUSSIE' : brandName;
              // const isChecked = brandFilter?.brands[CorrectBrandName] || false;
              return (
                <ChipWithImage
                  key={'Custom_'+filtersWithImage[index].Name}
                  title={filtersWithImage[index].Name}
                  image={imgSrcByEnv() + filtersWithImage[index].Thumbnail?.fullpath}
                  onClick={() => {
                    // contentSquarePageEvent('brandFilter_' + CorrectBrandName);
                    // dispatch({
                    //   type: actions.ADD_SEARCH_FILTER,
                    //   payload: {
                    //     name: 'Brand',
                    //     brands: {
                    //       ...brandFilter?.brands,
                    //       [CorrectBrandName]: !isChecked,
                    //     },
                    //   },
                    // });
                    //contentSquarePageEvent('brandSelectFilter')
                    dispatch({
                      type: actions.ADD_SEARCH_FILTER,
                      payload: {
                        name: 'Custom_' + filtersWithImage[index].Name,
                        value: filtersWithImage[index].Name,
                        checked: isCustomFilter.includes('Custom_' + filtersWithImage[index].Name) ? false : true,
                      },
                    });
                  }}
                  colors={isCustomFilter.includes('Custom_' + filtersWithImage[index].Name) ? 'dark' : 'light'}
                  isExpand={false}
                  background="#ffffff"
                />
              );
            }
          })}
        </ChipWithImageContainerStyled>
      )}
    </FiltersStyled>
  );
};

const ChipWithImageContainerStyled = styled.div`
  display: flex;
  margin: 10px 0;
  overflow-x: auto;
  white-space: nowrap;
  gap: 10px;
  scrollbar-width: none;
`;
