import { PopularProducts } from '../../../components/PopularProducts';
import { IProduct } from '../../../types';
interface IShuffledProducts {
  shuffledSimilar: IProduct[];
  shuffledRecommended: IProduct[];
}

export const HomeProductsSlider = ({
  shuffledProducts,
  title,
}: {
  shuffledProducts: IShuffledProducts;
  title: string;
}) => {
  const productsData = title.includes('Target')
    ? shuffledProducts.shuffledRecommended
    : shuffledProducts.shuffledSimilar;

  return (
    <>
      <PopularProducts products={productsData} title={title}></PopularProducts>
    </>
  );
};
