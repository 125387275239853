import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PiMagnifyingGlassBold } from 'react-icons/pi';
import { useNavigation } from './useNavigation';
import { theme } from '../../theme';
import { BsBoxArrowUp } from 'react-icons/bs';
import { GoArrowLeft } from 'react-icons/go';
import { useQuery } from '@apollo/client';
import { GET_PRODUCT_CATEGORY_BY_TERM } from '../../queries';
import styled from '@emotion/styled';

export const Navigation = () => {
  const { handleSearchSuggestions } = useNavigation();

  const location = useLocation();
  const navigate = useNavigate();

  const handleGoToPreviousScreen = () => {
    navigate(-1);
  };

  const { storeId = '', harvestId = '', termId } = useParams();

  const url = location.pathname;
  const slashCount = url.split('/').length - 1;

  const { data: nameData, loading: nameLoading } = useQuery(
    GET_PRODUCT_CATEGORY_BY_TERM({ termId }),
    {
      skip: !termId,
    }
  );

  const name = nameData?.getEPogTermListing?.edges[0]?.node?.Name;

  return (
    <NavContainer>
      <Nav>
        {slashCount > 2 && (
          <StyledPreviousScreenButtonWrapper left>
            <StyledPreviousScreenButton onClick={handleGoToPreviousScreen} />
          </StyledPreviousScreenButtonWrapper>
        )}

        <NavItems>
          <SearchInputWrapper>
            <SearchInputContainer>
              <>
                <SearchIcon>
                  <PiMagnifyingGlassBold
                    size="20"
                    color={theme.target.greyDark}
                  />
                </SearchIcon>
                <SearchInputStyled
                  type="text"
                  placeholder="What can we help you find?"
                  value={name || ''}
                  onChange={(e) => handleSearchSuggestions(e.target.value)}
                />
              </>
            </SearchInputContainer>
          </SearchInputWrapper>
        </NavItems>

        {slashCount > 2 && (
          <StyledPreviousScreenButtonWrapper left={false}>
            <StyledSecondSearchButton />
          </StyledPreviousScreenButtonWrapper>
        )}
      </Nav>
    </NavContainer>
  );
};

export const NavItems = styled.div`
  flex: 1;
  font-weight: 700;
  font-size: 12px;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  align-items: center;
`;

const StyledPreviousScreenButtonWrapper = styled.div<{ left?: boolean }>`
  justify-content: center;
  user-select: none;
  width: 30px;
  padding-left: ${({ left }) => (left ? '12px' : '0px')};
  margin-right: ${({ left }) => (left ? '0px' : '8px')};
`;

const StyledPreviousScreenButton = styled(GoArrowLeft)`
  font-size: 24px;
  color: ${theme.target.white};
`;

const StyledSecondSearchButton = styled(BsBoxArrowUp)`
  font-size: 24px;
  color: ${theme.target.white};
`;

const SearchInputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 0 16px;
`;

const SearchInputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 0px;
`;

const SearchInputStyled = styled.input`
  pointer-events: none;
  width: 100%;
  padding: 10px;
  padding-left: 40px;
  border-radius: 8px;
  height: 50px;
  font-size: 15px;
  font-weight: 500;
  border: 1px solid #ccc;

  ::placeholder {
    color: ${theme.target.grey};
  }
`;

const SearchIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: ${theme.target.black};
`;

const NavContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #c90000;
  z-index: 1000;
  color: ${(props: any) =>
    props.theme.navBg === '#FFFFFF' ? '#000000' : '#FFFFFF'};
`;

const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  max-width: 1128px;
  margin: 0 auto;
`;
