import React from 'react';
import styled from '@emotion/styled';
import { IProduct } from '../../types';
import { Link } from 'react-router-dom';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import { theme } from '../../theme';
import { AddToCartButton } from '../AddToCartButton';
interface IProps {
  product: IProduct;
  onClick: string;
  url: string;
}
export const SimilarProductItem = ({ product, onClick, url }: IProps) => (
  <LinkStyled to={onClick}>
    <SimilarProductStyled key={product.id}>
      <img
        src={`${imgSrcByEnv()}${
          product?.Gallery && product?.Gallery[0]?.image?.fullpath
        }`}
        alt="Product"
      />
      <PriceStyled>${product.RegularPrice}</PriceStyled>
      <NameStyled>{product.Name}</NameStyled>
      <AddToCartButton product={product} url={url} />
    </SimilarProductStyled>
  </LinkStyled>
);

const LinkStyled = styled(Link)`
  text-decoration: none;
  color: ${theme.target.black};
`;

const SimilarProductStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 41vw;
  min-width: 41vw;
  height: auto;
  margin-bottom: 20px;
  padding: 16px;
  color: black;
  font-size: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
  & > img {
    width: 100%;
    height: 150px;
    object-fit: contain;
  }
`;
const ImageStyled = styled.img`
  border-radius: 8px;
  object-fit: cover;
`;
const PriceStyled = styled.p`
  margin-top: 20px;
  font-weight: 700;
`;
const NameStyled = styled.p`
  display: -webkit-box;
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 13px;
  min-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`;
