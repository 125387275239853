import styled from '@emotion/styled';

export const HomeOfferDetails = () => (
  <OfferDetailsWrapper>
    <p>
      <span>*See offer details.</span> Restrictions apply. Pricing, promotions
      and availability may vary by location and at Target.com.
    </p>
  </OfferDetailsWrapper>
);

const OfferDetailsWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 48px 32px 0px 32px;

  p {
    font-size: 14px;
    line-height: 1.5;
  }

  span {
    font-weight: 700;
  }
`;
