import React from 'react';
import { useParams } from 'react-router-dom';
import { IProduct } from '../../types';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import { TCurrency } from '../../types/config';
import styled from '@emotion/styled';
import { AdvBannerItem } from '../AdvBannerItem';
import { normalizeNameForUrl } from '../../helpers';

export const AdvBanner = ({
  TermBannerImage,
  TermBannerProducts,
  TermBannerSponsoredBy,
}: {
  TermBannerImage: { fullpath: string };
  TermBannerProducts: IProduct[];
  TermBannerSponsoredBy: string;
  TermBannerText: string;
  Currency: TCurrency;
  AddProductButton?: any;
}) => {
  const { storeId = '', categoryId, harvestId = '' } = useParams();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        borderBottom: '1px solid lightgrey',
        paddingBottom: '8px',
        marginTop: '90px',
      }}
    >
      <StyledSponsorName>
        Sponsored by {TermBannerSponsoredBy}
      </StyledSponsorName>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <div>
          <img
            src={`${imgSrcByEnv()}${TermBannerImage?.fullpath}`}
            alt={TermBannerSponsoredBy}
            style={{
              width: '100px',
              height: '150px',
              objectFit: 'contain',
              background:
                'linear-gradient(to right, rgba(145,222,229,0.3), white 90%)',
            }}
          />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            overflowX: 'auto',
          }}
        >
          {TermBannerProducts.map((product) => {
            const { id, Name, Price, RegularPrice, Brand, URLPromoCode } =
              product;
            const price = Price ? Price : RegularPrice;
            const brand = Brand ? Brand : '';
            let promoCode;

            if (URLPromoCode != null) {
              promoCode = '__promoCode-' + URLPromoCode;
            } else {
              promoCode = '__promoCode-';
            }

            return (
              <AdvBannerItem
                key={product.id}
                product={product}
                onClick={
                  categoryId
                    ? `/${storeId}/${harvestId}/ip/${categoryId}/${id}__${brand}__${normalizeNameForUrl(
                        Name
                      )}}_${price}_${promoCode}`
                    : `/${storeId}/${harvestId}/ips/${id}__${brand}__${normalizeNameForUrl(
                        Name
                      )}_${price}_${promoCode}`
                }
                url={
                  categoryId
                    ? `/${storeId}/${harvestId}/ip/${categoryId}/${id}__${brand}__${normalizeNameForUrl(
                        Name
                      )}}_${price}_${promoCode}`
                    : `/${storeId}/${harvestId}/ips/${id}__${brand}__${normalizeNameForUrl(
                        Name
                      )}_${price}_${promoCode}`
                }
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const StyledSponsorName = styled.div`
  display: flex;
  width: 100%;
  font-size: 10px;
  font-weight: 600;
  padding: 8px 8px;
  border-bottom: 1px solid lightgray;
  margin-bottom: 8px;
`;
