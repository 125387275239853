import React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { DeliveryDate } from '../../components/DeliveryDate';
import { useAppContext } from '../../context';
import { IProduct } from '../../types';
import { useCartHook } from './useCartHook';
import { SimilarProducts } from '../../components/SimilarProducts';
import { CartRouteStyled, HideDetails } from './styles';
import { ReactSVG } from 'react-svg';
import { useHref, useNavigate, useParams } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { useConfiguration } from '../../hooks/useConfiguration';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import { CheckoutButtonPositionEnum, IpdpField } from '../../types/config';
import { useQuery } from '@apollo/client';
import { GET_LOCALIZED } from '../../queries';
import { PdpFieldName } from '../../types/config';
import styled from '@emotion/styled';
import { theme } from '../../theme';
import { OrderSummaryItem } from './cart/cartOrder/OrderSummaryItem';
import { IoChevronDownSharp } from 'react-icons/io5';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { ClipboardIcon } from '../../assets/icons/ClipboardIcon';
import { TickIcon } from '../../assets/icons/TickIcon';
import { GiftIcon } from '../../assets/icons/GiftIcon';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { GoArrowLeft } from 'react-icons/go';
import { CartSavedItemsViewer } from './cart/cartSavedItems/CartSavedItemsViewer';
import { CartFooterSection } from './cart/cartFooter/CartFooterSection';
import { CartShippingSection } from './cart/CartShippingSection';
import { CartEmptySection } from './cart/emptyCart/CartEmptySection';

const checkRecommendedProductsEnabled = (PDPFields?: IpdpField) => {
  if (!PDPFields) return false;
  if (Array.isArray(PDPFields)) {
    return PDPFields.some(
      (PDPField) =>
        PDPField.Name === PdpFieldName.Recommended ||
        PDPField.Name === PdpFieldName.ProductYouMakeLike
    );
  } else {
    return (
      PDPFields.Name === PdpFieldName.Recommended ||
      PDPFields.Name === PdpFieldName.ProductYouMakeLike
    );
  }
};

export const CartRoute = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [recommendedProductsEnabled, setRecommendedProductsEnabled] =
    React.useState(false);

  const {
    similarLoading,
    handleCart,
    checkout,
    totalCartValue,
    totalItemsInCart,
    shuffledProducts,
    checkoutMessage,
  } = useCartHook();

  const { storeId = '', harvestId = '' } = useParams();

  // const { LanguageVersion } = useConfiguration({ storeId, harvestId });
  const {
    LanguageVersion,
    CheckoutButtonPosition,
    Currency,
    eRetailer,
    PDPFields,
  } = useConfiguration({
    storeId,
    harvestId,
  });

  React.useEffect(() => {
    setRecommendedProductsEnabled(checkRecommendedProductsEnabled(PDPFields));
  }, [PDPFields]);

  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });

  const {
    value: { cart },
  } = useAppContext();

  const navigate = useNavigate();

  let href = window.location.pathname;

  /*if (checkoutMessage != "" && href.indexOf('checkout') == -1) {
    navigate(href + "/checkout")
  }*/

  const normalizeNameForUrl = (name: string | null) => {
    if (!name) return '';
    return name
      .normalize('NFD')
      .replaceAll(' ', '_')
      .replace(/[^a-zA-Z0-9_ ]/g, '');
  };
  //product.id + '__' + product.Brand + '__' + normalizeNameForUrl(product.Name) + '_' + price + promoCode + '_cartadd' + product.amount
  const navigateToProduct = (
    id: string,
    name: string,
    brand: string,
    price: number,
    promo: string,
    amount: number
  ) =>
    navigate(
      `/${storeId}/${harvestId}/ips/${id}__${brand}__${name}_${price}${promo}_cartadd${amount}`
    );

  const currency = Currency ? Currency : '$';
  const totalItemsDefinition = totalItemsInCart === 1 ? 'item' : 'items';

  const emptyCart = cart.length === 0;
  const oneItemInCart = totalItemsInCart === 1;

  const handleGoToPreviousScreen = () => {
    navigate(-1);
  };

  return (
    <CartRouteStyled>
      <CartValueWrapper>
        <CartDetails>
          <CartValueDetails>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <StyledPreviousScreenButtonWrapper left>
                  <StyledPreviousScreenButton
                    onClick={handleGoToPreviousScreen}
                  />
                </StyledPreviousScreenButtonWrapper>
                <CartHeader>Cart</CartHeader>
              </div>
            </div>

            <CartSubHeader>
              {totalItemsInCart !== 0 ? (
                <>
                  {totalCartValue} US$ subtotal <span>&bull;</span>{' '}
                  {totalItemsInCart} {oneItemInCart ? 'item' : 'items'}
                </>
              ) : checkoutMessage !== '' ? (
                'Checkout finished'
              ) : (
                'Your cart is empty'
              )}
            </CartSubHeader>
          </CartValueDetails>
        </CartDetails>
      </CartValueWrapper>

      {!emptyCart ? (
        <>
          <OrderSummaryWrapper>
            <CartShippingSection handleCart={handleCart} />
            <ContentWrapper>
              <ItemContainer>
                <Header>
                  <CartSectionHeader>Order summary</CartSectionHeader>
                </Header>
                <OrderSummaryItem
                  image={<ClipboardIcon />}
                  header={`${totalCartValue} US$ total`}
                  subheader={`${totalItemsInCart} ${totalItemsDefinition}`}
                  icon={<IoChevronDownSharp />}
                />
              </ItemContainer>
            </ContentWrapper>
            <>
              <CartFooterSection />
              <ComponentWrapper>
                <StyledCartButton onClick={() => checkout('checkout', cart)}>
                  Check out
                </StyledCartButton>
              </ComponentWrapper>
            </>
          </OrderSummaryWrapper>
        </>
      ) : (
        <CartEmptySection />
      )}
    </CartRouteStyled>
  );
};

const ComponentWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: space-between;
  bottom: 70px;
  left: 0;
  right: 0;
  padding: 16px;
  z-index: 1000;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.1);
  background-color: ${theme.target.greyLight};
`;

const StyledCartButton = styled.button<{ isCheckout?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  font-size: 20px;
  font-weight: 550;
  border-radius: 8px;
  color: ${theme.target.white};
  background-color: ${theme.target.red?.dark};
  border: ${({ isCheckout }) =>
    isCheckout ? 'none' : `2px solid ${theme.target.grey}`};
`;

const OrderSummaryWrapper = styled.div`
  padding: 16px 0px 0px 0px;
  background-color: ${theme.target.white};
  margin-bottom: 12px;

  img {
    width: 20px;
  }
`;

const Header = styled.div`
  padding-bottom: 16px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CartSectionHeader = styled.div`
  font-size: 20px;
  font-weight: 700;
  padding: 0px 16px;
`;

const CartValueWrapper = styled.div`
  background-color: #f8f8f8;
  height: 135px;
  z-index: 1;
`;

const CartDetails = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Arial;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  padding: 0px 16px;
  background-color: ${theme.target.white};
`;

const CartValueDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 0px;
`;

const CartHeader = styled.p`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 4px;
  color: ${theme.target.black};
`;

const CartSubHeader = styled.p`
  font-size: 14px;
  color: ${theme.target.greyDark};

  span {
    font-size: 24px;
    vertical-align: middle;
  }
`;

const StyledPreviousScreenButtonWrapper = styled.div<{ left?: boolean }>`
  justify-content: center;
  user-select: none;
  width: 30px;
`;

const StyledPreviousScreenButton = styled(GoArrowLeft)`
  font-size: 24px;
  color: ${theme.target.primary};
`;
