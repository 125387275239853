import React from 'react';
import {
  ButtonInCart,
  ButtonStyled,
  HeaderTypography,
  IconButtonStyled,
  SelectHeader,
  StyledBottom,
  StyledContent,
  StyledModalBox,
  StyledTitle,
} from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../hooks/useConfiguration';
import { useAppContext } from '../../context';
import { ProductVariants } from '../ProductVariants';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import ShippingMethods from '../ShippingMethods';
import { IProduct } from '../../types';
import { dynataAddToCart } from '../../helpers';
import { CircularProgress, Fade, Grow, Slide } from '@mui/material';
import ShippingInfo from '../ShippingInfo';
import { actions } from '../../context/actions';
import { contentSquarePageEvent } from '../../helpers';
import { ModalTypes } from '../Modals/types';
import { LoadingSpinner } from '../LoadingSpinnter';

export const ChoseOptions = ({ product, handleClose, isOpen }: any) => {
  const [shippingMethodSelected, setShippingMethodSelected] =
    React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [newAmount, setNewAmount] = React.useState(0);
  const [initialLoading, setInitialLoading] = React.useState(true);
  const [isModalOpen, setIsModalOpen] = React.useState(isOpen);

  let href = `${window.location.pathname}__${product.Name}`;

  const { storeId = '', categoryId, harvestId = '', productId } = useParams();
  const {
    dispatch,
    value: { cart },
  } = useAppContext();
  const { Currency } = useConfiguration({
    storeId,
    harvestId,
  });
  const price = product.Price !== 0 ? product.Price : product.RegularPrice;
  const currency = Currency ? Currency : '$';
  const pricePerUnit =
    product.PricePerUnit && product.PricePerUnit !== '0'
      ? ' (' + product.PricePerUnit + ')'
      : '';
  const firstImage = product?.Gallery[0]?.image?.fullpath;

  const productInCart = cart.find(
    (productInCart: IProduct) => product.id === productInCart?.id
  );
  const productAmount = productInCart ? productInCart.amount : 0;

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setInitialLoading(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  React.useEffect(() => {
    const amount = productInCart?.amount || 1;
    setNewAmount(amount);
  }, [cart, product]);

  const handleAddToCart = React.useCallback(
    (amount = newAmount) => {
      setIsLoading(true);
      if (amount > 0) {
        dispatch({
          type: actions.ADD_TO_CART,
          payload: {
            ...product,
            newAmount: amount,
            shippingMethod: shippingMethodSelected,
          },
        });
        dynataAddToCart(href + '_cartadd' + amount);
      } else {
        dispatch({
          type: actions.REMOVE_FROM_CART,
          payload: { type: 'REMOVE_ALL', product },
        });
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    },
    [dispatch, product, shippingMethodSelected, href, newAmount]
  );

  const handleQuantityChange = (product: any, quantity: any) => {
    setNewAmount(quantity);
    setTimeout(() => {
      handleAddToCart(quantity);
      setIsModalOpen(true);
    }, 800);
  };

  const openQuantitySelectModal = () => {
    setIsModalOpen(false);
    dispatch({
      type: actions.TOGGLE_MODAL,
      payload: {
        isModal: true,
        modalType: ModalTypes.quantity,
        product,
        modalContent: {
          handleQuantityChange: (product: any, newAmount: any) =>
            handleQuantityChange(product, newAmount),
          newAmount,
        },
      },
    });
  };

  const shippingMethod = productInCart
    ? productInCart.shippingMethod
    : shippingMethodSelected;

  return (
    <Fade in={isModalOpen} timeout={500}>
      <Slide direction="up" in={isModalOpen} mountOnEnter unmountOnExit>
        <StyledModalBox>
          <SelectHeader>
            <HeaderTypography id="quantity-select-modal-title">
              Choose Options
            </HeaderTypography>
            <IconButtonStyled onClick={handleClose}>
              <FontAwesomeIcon icon={faTimes} />
            </IconButtonStyled>
          </SelectHeader>
          <StyledContent>
            {initialLoading ? (
              <LoadingSpinner choseOption={true} />
            ) : (
              <>
                <StyledTitle>
                  {firstImage && (
                    <img
                      src={`${imgSrcByEnv()}${firstImage}`}
                      alt="product packshot"
                    />
                  )}
                  <div className="content">
                    <div className="price-container">
                      {price && (
                        <div className="price">
                          {product.Price !== 0 ? (
                            <div>
                              {currency}
                              {product.Price.toFixed(2)}{' '}
                              <span className="oldPrice">
                                {currency}
                                {product.RegularPrice.toFixed(2)}
                              </span>
                            </div>
                          ) : (
                            <div>
                              {currency}
                              {product.RegularPrice.toFixed(2)}
                            </div>
                          )}
                          <span className="pricePerUnit">{pricePerUnit}</span>
                        </div>
                      )}
                    </div>
                    <h2>{product.Name}</h2>
                  </div>
                </StyledTitle>
                {product?.Attribute &&
                  product.Attribute.length > 0 &&
                  product.Attribute[0]?.AttrName &&
                  product.Attribute[0]?.AttrValue !== 'value' && (
                    <ProductVariants
                      product={product}
                      storeId={storeId}
                      harvestId={harvestId}
                      categoryId={categoryId}
                    />
                  )}
                <div className="shipping-method">
                  {!productInCart && (
                    <ShippingMethods
                      shippingMethodSelected={shippingMethodSelected}
                      setShippingMethodSelected={setShippingMethodSelected}
                      storeId={storeId}
                      harvestId={harvestId}
                    />
                  )}
                </div>
              </>
            )}
          </StyledContent>
          <StyledBottom>
            {isLoading ? (
              <ButtonStyled variant="contained">
                <CircularProgress size={24} style={{ color: 'white' }} />
              </ButtonStyled>
            ) : !isLoading && productAmount > 0 ? (
              <Grow in={!isLoading && productAmount > 0}>
                <ButtonInCart
                  variant="contained"
                  onClick={openQuantitySelectModal}
                >
                  {productAmount} in cart
                  <span>
                    <FontAwesomeIcon className="faIcon" icon={faChevronDown} />
                  </span>
                </ButtonInCart>
              </Grow>
            ) : (
              <ButtonStyled
                variant="contained"
                onClick={() => handleAddToCart(newAmount)}
              >
                Add to cart
              </ButtonStyled>
            )}
          </StyledBottom>
        </StyledModalBox>
      </Slide>
    </Fade>
  );
};
