import { theme } from '../../../theme';
import { useAppContext } from '../../../context';
import { IProduct } from '../../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@mui/material';
import { LoadingSpinner } from '../../../assets/LoadingSpinner';
import { SpinnerStyled } from '../../ChoseOptions/styles';
import styled from '@emotion/styled';

export const AddToCart = ({
  handleAddToCartChange,
  product,
  buttonTitle,
  isLoading,
}: any) => {
  const {
    value: { cart },
  } = useAppContext();
  const productInCart = cart.find(
    (productInCart: IProduct) => product.id === productInCart?.id
  );

  const productAmount = productInCart ? productInCart.amount : 0;

  return productAmount > 0 ? (
    <ButtonInCart variant="contained" onClick={handleAddToCartChange}>
      {productAmount} in cart
      <span>
        <FontAwesomeIcon className="faIcon" icon={faChevronDown} />
      </span>
    </ButtonInCart>
  ) : (
    <AddButton className="addItem" onClick={handleAddToCartChange}>
      {isLoading ? <SpinnerStyled /> : buttonTitle}
    </AddButton>
  );
};

export const AddButton = styled.button<{}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 35px;
  background: ${theme.target.primary};
  border-radius: 3px;
  border: none;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0em;
  color: ${theme.target.white};
  cursor: pointer;
`;
export const ButtonInCart = styled(Button)`
  color: ${theme.target.green};
  background: ${theme.target.white};
  border: 1px solid ${theme.target.green};
  border-radius: 3px;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 500;
  width: 100%;
  height: 35px;
  text-transform: capitalize;
  display: flex;
  align-items: center;

  .faIcon {
    color: ${theme.target.greyDark};
    margin-left: 8px;
  }

  &:hover {
    background: transparent;
  }
`;
