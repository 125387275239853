import React from 'react';

import styled from '@emotion/styled';
import { SimilarProducts } from '../SimilarProducts';
import { IProduct } from '../../types';

export const PopularProducts = ({
  products,
  className,
  title,
}: {
  products: IProduct[];
  className?: string;
  title: string | undefined;
}) => {
  return (
    <PopularProductsStyled>
      <div className="header">
        <StyledSectionTitle>{title}</StyledSectionTitle>
      </div>
      <div className="popular-item">
        <SimilarProducts className={className} products={products} />
      </div>
    </PopularProductsStyled>
  );
};

const PopularProductsStyled = styled.div`
  background: #ffffff;
  padding: 32px 16px;

  ::-webkit-scrollbar {
    display: none;
  }

  .header {
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

const StyledSectionTitle = styled.p`
  display: flex;
  margin: 0px 0px 32px 0px;
  font-size: 18px;
  font-weight: 700;
`;
