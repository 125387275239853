import styled from "@emotion/styled";

export const ShippingInfoContainer = styled.div`
      margin: 16px 0;
      display: flex;
      flex-direction: column;
      gap: 10px;
    .shipping-title {
      font-weight: bold;
      color: #333;
      font-size: 18px;
    }

    .shipping-date {
      font-size: 14px;
      font-weight: bold;
      color: #388e3c;
    }
    .shipping-details {
      font-size: 14px;
      color: #666;
    }
`