import styled from "@emotion/styled";
import { theme } from "../../../theme";
import { IconButton } from "@mui/material";

export const BrandsStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const ContentStyled = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  width: 100%;
  height: 80vh;
  .checkboxes {
    .MuiFormControlLabel-root {
      align-items: center;
      border-bottom: 1px solid ${theme.target.grey};
      padding: 20px 10px;
      .MuiTypography-root {
        font-size: 20px;
        color: #333;
      }
      .MuiCheckbox-root {
        color: #666;
        &.Mui-checked {
          color: ${theme.target.green};
        }
        .MuiSvgIcon-root {
          font-size: 24px;
        }
      }
      .MuiIconButton-root {
        padding: 8px;
        border-radius: 4px;
        border: 1px solid #ddd;
      }
    }
  }
`;
export const IconButtonStyled = styled(IconButton)`
  & > svg {
    color: ${theme.target.greyDark};
  }
`;

export const ButtonsContainer = styled.div`
  background-color: ${theme.target.white};
  padding: 16px;
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 70px;
  z-index: 1000;
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 700;
  border-bottom: 1px solid ${theme.target.grey};
`;
