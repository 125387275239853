import React from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import { GET_LOCALIZED } from '../../queries';
import { useNavigate, useParams } from 'react-router-dom';
import { useConfiguration } from '../../hooks/useConfiguration';

export const FooterNav = () => {
  const { storeId = '', harvestId = '' } = useParams();

  const { LanguageVersion } = useConfiguration({ storeId, harvestId });

  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });

  let footerLinks = '';

  if (translation?.getLocalization.FooterLinks != undefined) {
    footerLinks = translation?.getLocalization.FooterLinks;
  }

  let list: String[] = footerLinks.split('\n');

  const list2: String[] = [
    'All Departments',
    'Store Directory',
    'Our Company',
    'Sell on Store.com',
    'Help',
    'COVID-19 Vaccine Scheduler',
    'Product Recalls',
    'Accessibility',
    'Tax Exempt Program',
    'Get the App',
    'Sign-up for Email',
    'Terms of Use',
    'Privacy & Security',
    'CA Privacy Rights',
    'California Supply Chain Act',
    'Do Not Sell My Personal Information',
    'Request My Personal Information',
  ];
  return (
    <FooterContainer>
      {/* <div className="footer-first">
        {list.map((item: String, index: number) => {
          return <span key={index}>{item}</span>;
        })}
      </div>
      <hr />
      <div className="footer-second">{translation?.getLocalization.AllRightsReserved}</div> */}
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  margin-top: 68px;
  background-color: ${({ theme }: any) => theme.footerMenuBg};
  color: ${({ theme }: any) => theme.footerFontColor};
  text-align: center;
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 10px;
  line-height: 24px;
  padding: 20px 0 0;

  .footer-first {
    max-width: 1128px;
    margin: 0 auto;
    span {
      margin-right: 16px;
    }
    @media (max-width: 768px) {
      padding: 0 15px 15px;
    }
  }

  hr {
    border: 0.3px solid #ffffff;
    margin-top: 14px;
    margin-bottom: 0px;
  }

  .footer-second {
    color: ${({ theme }: any) =>
      theme.footerRightsColor
        ? theme.footerRightsColor
        : theme.footerFontColor};
    height: 30px;
    padding: 3.5px 0;
    background-color: ${({ theme }: any) => theme.footerRightsBg};
  }
`;
