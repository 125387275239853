import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useNavigate, useParams } from 'react-router-dom';
import { useConfiguration } from '../../hooks/useConfiguration';
import { IProduct } from '../../types';
import Pagination from '@mui/material/Pagination';
import { ProductCard } from '../ProductCard';
import { useAppContext } from '../../context';
import {
  normalizeNameForUrl,
  contentSquereCustomTag,
  artificialPageview,
} from '../../helpers';
import { actions } from '../../context/actions';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';

export const ProductsContainer = ({
  products,
  name,
}: {
  products: IProduct[];
  name: string;
}) => {
  const {
    dispatch,
    value: { isInlineFilters },
  } = useAppContext();

  const {
    storeId = '',
    categoryId,
    termId,
    pageNr,
    harvestId = '',
  } = useParams();

  const navigate = useNavigate();

  const { Columns, Rows } = useConfiguration({
    storeId,
    harvestId,
  });
  const currentPage = Number(pageNr) || 1;
  const [productLength, setProductLength] = React.useState(0);
  let itemsPerPage = Columns === 3 ? 9 : 16;

  if (Rows && Columns) {
    itemsPerPage = Columns * Rows;
  }
  const handlePaginationChange = (event: any, value: any) => {
    navigate(
      categoryId
        ? `/${storeId}/${harvestId}/category/${categoryId}/${value}`
        : `/${storeId}/${harvestId}/search/${termId}/${value}`
    );
  };

  React.useEffect(() => {
    const newLength = products.filter((item) => item).length;
    if (productLength !== newLength) {
      setProductLength(newLength);
      dispatch({ type: actions.SET_PRODUCT_LENGTH, payload: newLength });
    }
  }, [products]);

  useEffect(() => {
    if (window.location.pathname.includes('category')) {
      contentSquereCustomTag(
        1,
        'Path',
        window.location.pathname + window.location.hash.replace('#', '?__'),
        ''
      );
      contentSquereCustomTag(2, 'pageType', 'Category', '');
      artificialPageview(
        window.location.pathname + window.location.hash.replace('#', '?__')
      );
    }
    if (window.location.pathname.includes('search')) {
      contentSquereCustomTag(
        1,
        'Path',
        window.location.pathname + window.location.hash.replace('#', '?__'),
        ''
      );
      contentSquereCustomTag(2, 'pageType', 'SERP', '');
      artificialPageview(
        window.location.pathname + window.location.hash.replace('#', '?__')
      );
    }
  }, [name]);

  return (
    <ProductsContainerStyled
      Columns={Columns}
      isInlineFilters={isInlineFilters}
      count={productLength}
    >
      <ProductListStyled>
        {products.length > 0 &&
          products
            .slice(
              currentPage === 1 ? 0 : currentPage * itemsPerPage - itemsPerPage,
              currentPage * itemsPerPage
            )
            .map((item: IProduct, index: number) => {
              const price = item?.Price ? item?.Price : item?.RegularPrice;
              const brand = item?.Brand ? item?.Brand : '';
              const position = itemsPerPage * (currentPage - 1) + index + 1;
              let promoCode;

              if (item.URLPromoCode != null) {
                promoCode = '__promoCode-' + item.URLPromoCode;
              } else {
                promoCode = '__promoCode-';
              }

              let newColumns = 4;
              let cssClass = 'video';
              if (Columns !== undefined) { newColumns = Columns; }
              if (position === 1 || position % newColumns === 1) { cssClass = 'video fullsize'; }

              if (!item?.fullpath?.includes("Products")) {
                return (
                  <div className={cssClass}>
                    <video src={`${imgSrcByEnv()}${item?.Video?.data.fullpath}`} controls autoPlay muted loop/>
                  </div>
                )
              } else {
              return (
                item && (
                  <ProductCard
                    key={index}
                    product={item}
                    url={
                      categoryId
                        ? `/${storeId}/${harvestId}/ip/${categoryId}/${
                            item?.id
                          }__${brand}__${normalizeNameForUrl(
                            item?.Name
                          )}}_${price}_${position}${promoCode}`
                        : `/${storeId}/${harvestId}/ips/${
                            item?.id
                          }__${brand}__${normalizeNameForUrl(
                            item?.Name
                          )}_${price}_${position}${promoCode}`
                    }
                    onClick={
                      categoryId
                        ? `/${storeId}/${harvestId}/ip/${categoryId}/${
                            item?.id
                          }__${brand}__${normalizeNameForUrl(
                            item?.Name
                          )}}_${price}_${position}${promoCode}`
                        : `/${storeId}/${harvestId}/ips/${
                            item?.id
                          }__${brand}__${normalizeNameForUrl(
                            item?.Name
                          )}_${price}_${position}${promoCode}`
                    }
                  />
                )
              );
              }
            })}
      </ProductListStyled>
      {productLength ? (
        <div className="pagination">
          <Pagination
            shape="rounded"
            count={Math.ceil(productLength / itemsPerPage)}
            page={currentPage}
            onChange={handlePaginationChange}
            hidePrevButton={currentPage === 1}
            hideNextButton={
              currentPage === Math.ceil(productLength / itemsPerPage)
            }
          />
        </div>
      ) : (
        ''
      )}
    </ProductsContainerStyled>
  );
};

const ProductsContainerStyled = styled.div<{
  Columns?: number;
  isInlineFilters: boolean;
  count?: number;
}>`
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-left: 5px;
  }

  .fullsize {
    width: 100%;

    grid-column: 1 / span 4;

     @media (max-width: 1140px) {
      grid-column: auto;
    }
    @media (max-width: 768px) {
      grid-column: 1 / span 2;
    }
    @media (max-width: 550px) {
      grid-column: 1 / span 1;
    }
  }
    
  video {
    width: 100%;
    padding:26px 0 0 0;
  }

`;
export const ProductListStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
