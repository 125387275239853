import React from 'react';
import styled from '@emotion/styled';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import { theme } from '../../theme';

export const ImageCarousel = ({ CarouselItems }: any) => (
  <CarouselStyled>
    <Swiper
      modules={[Pagination]}
      pagination={{ dynamicBullets: true }}
      className="swiper"
    >
      {CarouselItems?.length &&
        CarouselItems.map(({ image }: any, key: number) => (
          <SwiperSlide key={key}>
            <Slide>
              <img src={`${imgSrcByEnv()}${image.fullpath}`} alt="carousel" />
            </Slide>
          </SwiperSlide>
        ))}
    </Swiper>
  </CarouselStyled>
);

const CarouselStyled = styled.div`
  width: 40%;
  overflow: hidden;
  .swiper {
    padding-bottom: 35px;
  }
  .swiper .swiper-pagination-bullet {
    background: ${theme.target.black};
  }
`;

const Slide = styled.div`
  & > img {
    width: 100%;
    max-height: 200px;
    object-fit: contain;
    border-radius: 5px;
  }
`;
