export enum ModalTypes {
  filtersMenu,
  priceRange,
  brands,
  sorting,
  rating,
  variants,
  categories,
  specialOffers,
  quantity,
  choseOptions,
}
