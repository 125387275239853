import { MdLockOutline } from 'react-icons/md';
import { theme } from '../../../../theme';
import styled from '@emotion/styled';

export const CartFooterSection = () => {
  return (
    <FooterContainer>
      <Text>Free returns online and in stores</Text>
      <IconContainer>
        <LockIcon />
        <IconText>Shop safely</IconText>
      </IconContainer>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  font-weight: 600;
  margin-bottom: 135px;
  background-color: ${theme.target.greyDark};
  color: ${theme.target.white};
`;

const Text = styled.p`
  font-size: 14px;
  margin-bottom: 4px;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const IconText = styled.p`
  margin-left: 4px;
  font-size: 14px;
`;

const LockIcon = styled(MdLockOutline)`
  font-size: 14px;
`;
