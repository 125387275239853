import React, { useState } from 'react';
import {
  ProductContainer,
  DescriptionContainer,
  LinkStyled,
  ButtonContainerStyled,
} from './styles';
import { IProduct } from '../../types';
import { useConfiguration } from '../../hooks/useConfiguration';
import { useParams } from 'react-router-dom';
import { Rating, Stack } from '@mui/material';
import { AddToCartButton } from '../AddToCartButton';
import { ImageCarousel } from '../Carousel/ImageCarousel';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';

export const ProductCard = ({
  product,
  onClick,
  url,
}: {
  product: IProduct;
  onClick: string;
  url: string;
}) => {
  const { storeId = '', harvestId = '' } = useParams();
  const { Currency } = useConfiguration({
    storeId,
    harvestId,
  });
  const [isFavorite, setFavorite] = useState(false as boolean);
  const handlerAddToFavorites = (
    e: React.MouseEvent<HTMLOrSVGElement, MouseEvent>
  ) => {
    e.preventDefault();
    setFavorite((prev) => !prev);
  };
  const currency = Currency ? Currency : '$';
  return (
    <LinkStyled to={onClick}>
      <ProductContainer>
        <ImageCarousel CarouselItems={product.Gallery} />
        <DescriptionContainer>
          {product.Sponsored && <p className="sponsored">Sponsored</p>}
          {product.LabelText && (
            <p className="limitedTimeDeal">{product.LabelText}</p>
          )}
          {product.Price > 0 && product.RegularPrice > 0 ? (
            <>
              <p className="price">
                {currency}
                {product.Price.toFixed(2)}{' '}
              </p>
              <p className="oldPrice">
                typical price:
                <s>
                  {' '}
                  {currency}
                  {product.RegularPrice.toFixed(2)}
                </s>
              </p>
            </>
          ) : (
            <p className="price">
              {product.RegularPrice > 0 &&
                `${currency} ${product.RegularPrice.toFixed(2)}`}
            </p>
          )}
          {product.Name && <p className="name">{product.Name}</p>}
          <div className="rating">
            <Stack spacing={1}>
              <Rating
                name="half-rating-read"
                value={product.RROverall}
                precision={0.5}
                readOnly
              />
            </Stack>
            <span>{product?.RRAmount}</span>
          </div>
          <div className="promo">
            {product.PromoImage && (
              <img
                src={`${imgSrcByEnv()}${
                  product?.PromoImage && product?.PromoImage.fullpath
                }`}
                alt="promo information"
              />
            )}
            <p>{product.PromoText}</p>
          </div>

          <ButtonContainerStyled isActive={isFavorite}>
            <AddToCartButton product={product} url={url} />
            {isFavorite ? (
              <FaHeart onClick={handlerAddToFavorites} />
            ) : (
              <FaRegHeart onClick={handlerAddToFavorites} />
            )}
          </ButtonContainerStyled>
        </DescriptionContainer>
      </ProductContainer>
    </LinkStyled>
  );
};
