import React from 'react';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import { useQuery } from '@apollo/client';
import { GET_LOCALIZED } from '../../queries';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../hooks/useConfiguration';
import { theme } from '../../theme';

export const FilterModalButtons = ({ onSubmit, onClear }: any) => {
  const { storeId = '', harvestId = '' } = useParams();
  const { LanguageVersion } = useConfiguration({ storeId, harvestId });
  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });

  return (
    <ComponentStyled>
      <Button fullWidth onClick={onClear} className="reset-button">
        {translation?.getLocalization.Clear}
      </Button>
      <Button
        fullWidth
        variant="contained"
        onClick={onSubmit}
        className="confirm-button"
      >
        {translation?.getLocalization.ViewResults}
      </Button>
    </ComponentStyled>
  );
};

const ComponentStyled = styled.div`
  bottom: 10px;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: ${theme.target.white};
  display: flex;
  justify-content: space-between;
  width: 100%;
  .reset-button {
    color: ${theme.target.footerFontColor};
    background-color: ${theme.target.grey};
  }
  .confirm-button {
    color: ${theme.target.white};
    background-color: ${theme.target.primary};
  }
  & > button {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    padding-top: 9px;
    padding-bottom: 9px;
    text-transform: capitalize;
  }
  & > button:first-of-type {
    margin-right: 24px;
  }
`;
