import React from 'react';
import { ShippingInfoContainer } from './styles';

interface ShippingInfoProps {
  shippingMethodSelected: string;
}

const ShippingInfo = ({ shippingMethodSelected }: ShippingInfoProps) => {
  const shippingInfo = () => {
    switch (shippingMethodSelected) {
      case 'pickup':
        return {
          title: 'Pickup',
          date: 'GSet it in 2 hours',
          details: 'Free for Plus, $4 for Club Members',
        };
      case 'delivery':
        return {
          title: 'Delivery',
          date: 'Get it in 3 hours',
          details: '$8 for Plus, $12 for Club Members',
        };
      case 'shipping':
        return {
          title: 'Shipping',
          date: 'Get it in 2-3 days',
          details: 'Free for Plus, From $5.50 for Club Members',
        };
      default:
        return {
          title: 'Select a Shipping Method',
          date: '',
          details: 'Please select a shipping method',
        };
    }
  };

  return (
    <ShippingInfoContainer>
      <p className="shipping-title">{shippingInfo().title}</p>
      <p className="shipping-date">{shippingInfo().date}</p>
      <p className="shipping-details">{shippingInfo().details}</p>
    </ShippingInfoContainer>
  );
};

export default ShippingInfo;
