import hero from '../../../assets/images/homescreen/hero.jpg';
import styled from '@emotion/styled';

export const HomeHeroSection = () => (
  <ComponentWrapper>
    <HeroImage src={hero} alt="hero" />
  </ComponentWrapper>
);

const ComponentWrapper = styled.div`
  margin: 0 16px;
`;

const HeroImage = styled.img`
  width: 100%;
  border-radius: 20px;
`;
