import { useParams } from 'react-router-dom';
import { IProduct } from '../../types';
import { normalizeNameForUrl } from '../../helpers';
import styled from '@emotion/styled';
import { SimilarProductItem } from '../SimilarProductItem';

export const SimilarProducts = ({
  products,
}: {
  products: IProduct[];
  className?: string;
}) => {
  const { categoryId, storeId, harvestId } = useParams();
  return (
    <HorizontalScrollContainer>
      {products?.map((product: IProduct) => {
        const { id, Name, Price, RegularPrice, Brand, URLPromoCode } = product;

        const price = Price !== 0 ? Price : RegularPrice;
        const brand = Brand ? Brand : '';
        let promoCode;
        if (URLPromoCode != null) {
          promoCode = '__promoCode-' + URLPromoCode;
        } else {
          promoCode = '__promoCode-';
        }

        return (
          <SimilarProductItem
            key={product.id}
            product={product}
            url={
              categoryId
                ? `/${storeId}/${harvestId}/ip/${categoryId}/${id}__${brand}__${normalizeNameForUrl(
                    Name
                  )}_${price}_${promoCode}`
                : `/${storeId}/${harvestId}/ips/${id}__${brand}__${normalizeNameForUrl(
                    Name
                  )}_${price}_${promoCode}`
            }
            onClick={
              categoryId
                ? `/${storeId}/${harvestId}/ip/${categoryId}/${id}__${Brand}__${normalizeNameForUrl(
                    Name
                  )}_${Price}_${promoCode}`
                : `/${storeId}/${harvestId}/ips/${id}__${Brand}__${normalizeNameForUrl(
                    Name
                  )}_${Price}_${promoCode}`
            }
          />
        );
      })}
    </HorizontalScrollContainer>
  );
};

const HorizontalScrollContainer = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  gap: 15px;
  scrollbar-width: none;
`;
