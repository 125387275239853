import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_PRODUCT_CATEGORY_BY_TERM } from '../../../queries';
import { useParams } from 'react-router-dom';
import { IProduct } from '../../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FilterModalCommonStyles } from '../commonStyles';
import { FilterModalButtons } from '../../FilterModalButtons';
import {
  BrandsStyled,
  HeaderStyled,
  ContentStyled,
  ButtonsContainer,
  IconButtonStyled,
} from './styles';
import { useBrandsFilter } from './useBrandsFilter';
import { useConfiguration } from '../../../hooks/useConfiguration';
import { GET_LOCALIZED } from '../../../queries';
import { CustomCheckbox } from '../../SucessCheckbox';

export const BrandsFilterModal = ({
  productsBeforeFiltering,
  buttonsOff,
  header,
  handleClose,
}: any) => {
  const {
    searchBar,
    setSearchBarInput,
    handleSubmit,
    handleClear,
    handleCheckboxes,
    checked,
  } = useBrandsFilter({ productsBeforeFiltering });

  const { storeId = '', termId, harvestId = '' } = useParams();

  const { LanguageVersion } = useConfiguration({ storeId, harvestId });

  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });
  const { data, loading } = useQuery(
    GET_PRODUCT_CATEGORY_BY_TERM({
      termId: termId?.split('__')[0],
    })
  );

  const edges = data?.getEPogTermListing?.edges;

  const brands = edges
    .map((edge: any) => edge?.node?.BrandFilterElements)
    .flat();
  if (brands.length > 1) {
    return (
      <FilterModalCommonStyles buttonsOff={buttonsOff}>
        {header && (
          <HeaderStyled>
            <span>Brands</span>
            <IconButtonStyled onClick={handleClose}>
              <FontAwesomeIcon icon={faTimes} />
            </IconButtonStyled>
          </HeaderStyled>
        )}
        <BrandsStyled>
          <ContentStyled>
            <div className="checkboxes">
              <FormGroup>
                {brands &&
                  Object.values(brands).map((value, index: number) => {
                    return (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            name={brands[index].BrandName}
                            checked={checked[brands[index].BrandName]}
                            onChange={() =>
                              handleCheckboxes(brands[index].BrandName)
                            }
                            color="success"
                            sx={{
                              '& .MuiSvgIcon-root': {
                                fontSize: 70,
                                borderRadius: 20,
                              },
                            }}
                          />
                        }
                        label={brands[index].BrandName}
                      />
                    );
                  })}
              </FormGroup>
            </div>
          </ContentStyled>
          <ButtonsContainer>
            <FilterModalButtons onSubmit={handleSubmit} onClear={handleClear} />
          </ButtonsContainer>
        </BrandsStyled>
      </FilterModalCommonStyles>
    );
  } else {
    return (
      <FilterModalCommonStyles buttonsOff={buttonsOff}>
        {header && (
          <HeaderStyled>
            <span>Brands</span>
            <IconButtonStyled onClick={handleClose}>
              <FontAwesomeIcon icon={faTimes} />
            </IconButtonStyled>
          </HeaderStyled>
        )}
        <BrandsStyled>
          <ContentStyled>
            <div className="checkboxes">
              <FormGroup>
                {productsBeforeFiltering ? (
                  productsBeforeFiltering
                    .filter(
                      (product: IProduct, index: number, arr: IProduct[]) =>
                        product.Brand?.toLowerCase().includes(
                          searchBar.toLowerCase()
                        ) &&
                        index ===
                          arr.findIndex((item) => item.Brand === product.Brand)
                    )
                    .map((product: IProduct, index: number) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <CustomCheckbox
                            name={product.Brand}
                            checked={checked[product.Brand]}
                            onChange={() => handleCheckboxes(product.Brand)}
                          />
                        }
                        label={product.Brand}
                      />
                    ))
                ) : (
                  <div></div>
                )}
              </FormGroup>
            </div>
          </ContentStyled>
          <ButtonsContainer>
            <FilterModalButtons onSubmit={handleSubmit} onClear={handleClear} />
          </ButtonsContainer>
        </BrandsStyled>
      </FilterModalCommonStyles>
    );
  }
};
