import { IoFastFood, IoPersonCircleOutline } from 'react-icons/io5';
import { theme } from '../../theme/index';
import { FaWallet } from 'react-icons/fa6';
import { BsCartFill } from 'react-icons/bs';
import styled from '@emotion/styled';
interface IBottomNavigationButton {
  iconName: string;
  label: string;
  active: boolean;
  icon: JSX.Element;
  badge?: JSX.Element;
  onClick: any;
}

export const BottomNavigationButton = ({
  iconName,
  label,
  active,
  icon,
  badge,
  onClick,
}: IBottomNavigationButton) => {
  const renderIcon = (iconName: string) => {
    const iconMap: { [key: string]: JSX.Element } = {
      home: icon,
      essentials: <IoFastFood size={23} />,
      wallet: <FaWallet size={22} />,
      cart: (
        <CartIconWrapper>
          <BsCartFill size={23} />
          {badge}
        </CartIconWrapper>
      ),
      profile: <IoPersonCircleOutline size={24} />,
    };

    return iconMap[iconName] || null;
  };

  const handleClick = () => {
    onClick();
  };

  return (
    <IconButton onClick={handleClick}>
      <IconWrapper active={active}>
        {renderIcon(iconName)}
        <IconLabel>{label}</IconLabel>
      </IconWrapper>
    </IconButton>
  );
};

const IconButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  cursor: pointer;
  width: 100px;

  -webkit-tap-highlight-color: transparent;
`;

const IconWrapper = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-size: 20px;
  color: ${({ active }) =>
    active ? `${theme.target.primary}` : `${theme.target.grey}`};
`;

const IconLabel = styled.span`
  font-size: 10px;
  padding-top: 4px;
  font-weight: 600;
  margin-top: 4px;
  color: ${theme.amazon.black};
`;

const CartIconWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;
