import styled from '@emotion/styled';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { theme } from '../../theme';
import { ReactComponent as Spinner } from '../../assets/spinner.svg';

export const StyledModalBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 800px;
  border-radius: 10px;
  background: ${theme.target.white};
  box-shadow: 24px;
  overflow-x: hidden;
  overflow-y: auto;
`;
export const LoadingConatiner = styled(Box)`
  height: 200px;
`

export const HeaderTypography = styled(Typography)`
  font-weight: 600;
  font-size: 18px;
`;
export const SpinnerStyled = styled(Spinner)`
  width: 25px;
  height: 25px;
`;
export const SelectHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
  height: 50px;
`;
export const IconButtonStyled = styled(IconButton)`
  & > svg {
    color: ${theme.target.greyDark};
  }
`;

export const ButtonStyled = styled(Button)`
  color: ${theme.target.white};
  background: ${theme.target.primary};
  padding: 9px 16px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  width: 100%;
  text-transform: capitalize;
  &:hover {
    background: ${theme.target.primary};
  }
`;
export const ButtonInCart = styled(Button)`
  color: ${theme.target.green};
  background: white;
  padding: 9px 16px;
  border: 1px solid ${theme.target.green};
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  width: 100%;
  text-transform: capitalize;
  display: flex;
  align-items: center;

  .faIcon {
    color: grey;
    margin-left: 8px;
  }

  &:hover {
    background: transparent;
  }
`;
export const StyledTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 20px;
  margin-left: 10px;
  width: 100%;
  img {
    width: 100px;
    height: 100px;
    margin-right: 20px;
  }
  .content {
    .price {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: -20px;
    }

    .oldPrice {
      color: gray;
      text-decoration: line-through;
      margin-left: 10px;
    }

    .pricePerUnit {
      font-size: 14px;
      color: gray;
      margin-left: 10px;
    }

    h2 {
      font-size: 16px;
      margin-right: 20px;
      margin-top: 0;
    }
  }
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
  .shipping-methods {
    margin-top: 10px;
    .container {
      display: flex;
    }
    .shipping-info {
      margin: 16px 0;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .shipping-title {
      font-weight: bold;
      color: ${theme.target.black};
      font-size: 20px;
    }

    .shipping-date {
      font-size: 14px;
      font-weight: bold;
      color: ${theme.target.green};
    }
    .shipping-details {
      font-size: 14px;
      color: ${theme.target.grey};
    }
  }
`;

export const StyledBottom = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.2);
  background: white;
`;