import styled from '@emotion/styled';
import { ModalTypes } from '../Modals/types';
import { theme } from '../../theme';

export const FiltersMenuStyled = styled.div<{
  modalType?: ModalTypes;
  isInlineFilters?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${({ modalType }) =>
    modalType === ModalTypes.filtersMenu ? '90vh' : 'auto'};
  width: ${({ modalType }) =>
    modalType === ModalTypes.filtersMenu ? '40vw' : 'auto'};

  @media (max-width: 767px) {
    width: auto;
  }

  max-width: 420px;
  min-height: ${({ isInlineFilters }) => (isInlineFilters ? 'auto' : '100vh')};

  .filters {
    flex: 1;
    overflow-y: auto;
    position: relative;
    z-index: 1;
    height: 100vh;
    .filter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 22px;
      span {
        letter-spacing: 0em;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
        cursor: pointer;
      }
      svg {
        font-size: 12px;
        cursor: pointer;
      }
    }
  }

  .filter-header {
    position: sticky;
    top: 0;
    background-color: #fff;
    border-bottom: 1px solid ${theme.target.grey};
    z-index: 2;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px;

    & > span {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0em;
    }
    & > svg {
      font-size: 16px;
      margin-right: 10px;
      cursor: pointer;
    }
    .back-icon {
      color: ${theme.target.primary};
    }
    & > svg:first-of-type {
      margin-right: 10px;
    }
    & > svg:last-of-type {
      margin-left: auto;
    }
  }

  .filter-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: #fff;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    & > div:first-of-type {
      flex-grow: 1;
      padding: 10px 16px;
      margin-right: 8px;
      font-size: 14px;
      color: ${theme.target.greyDark};
    }
    .button-container {
      display: flex;
      flex-direction: row;
      .reset-button {
        color: ${theme.target.footerFontColor};
        background-color: ${theme.target.grey};
      }
      .confirm-button {
        color: ${theme.target.white};
        background-color: ${theme.target.primary};
      }
      & > button {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0em;
        padding-top: 9px;
        padding-bottom: 9px;
        text-transform: capitalize;
      }
      & > button:first-of-type {
        margin-right: 24px;
      }
    }
  }
`;
