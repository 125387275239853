import styled from '@emotion/styled';
import React from 'react';
import { theme } from '../../theme';

export const LoadingSpinner = ({ choseOption }: any) => (
  <LoadingSpinnerWrapper choseOption={choseOption}>
    <div />
  </LoadingSpinnerWrapper>
);

const LoadingSpinnerWrapper = styled.div<{ choseOption: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ choseOption }) => (choseOption ? '20vh' : '100vh')};

  div {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    border: 8px solid #f3f3f3;
    animation: spin 1s linear infinite;
    border-top: 8px solid ${theme.target.primary};
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
