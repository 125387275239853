import styled from '@emotion/styled';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Fade, Typography } from '@mui/material';
import React, { useState } from 'react';

const Container = styled.div`
  margin-top: 10px;
  position: relative;
`;

const Header = styled.div<{ arrowPosition: any }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: ${({ arrowPosition }: any) =>
    arrowPosition === 'right' ? 'space-between' : 'initial'};
`;

const Content = styled(Box)`
  max-width: 400px;
  margin: 0 20px;

  img {
    max-width: 400px;
    width: 100%;
    height: auto;
  }

  table {
    max-width: 400px;
    width: 100%;
    overflow-x: auto;
    display: block;
  }
`;

const Arrow = styled.span<{ arrowPosition: any }>`
  ${({ arrowPosition }: any) =>
    arrowPosition === 'right'
      ? 'position: absolute; right: 10px;'
      : 'margin: 0 10px;'}
`;

export const ExpantableComponent = ({
  title,
  children,
  fontSize = '24px',
  arrowPosition = 'left',
}: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleContent = () => setIsOpen(!isOpen);

  return (
    <Container>
      <Header onClick={toggleContent} arrowPosition={arrowPosition}>
        {arrowPosition === 'left' && (
          <Arrow arrowPosition={arrowPosition}>
            <FontAwesomeIcon
              className="faIcon"
              icon={isOpen ? faChevronUp : faChevronDown}
            />
          </Arrow>
        )}
        <Typography variant="h3" style={{ fontSize }}>
          {title}
        </Typography>
        {arrowPosition === 'right' && (
          <Arrow arrowPosition={arrowPosition}>
            <FontAwesomeIcon
              className="faIcon"
              icon={isOpen ? faChevronUp : faChevronDown}
            />
          </Arrow>
        )}
      </Header>
      <Fade in={isOpen} unmountOnExit>
        <Content>{children}</Content>
      </Fade>
      <hr />
    </Container>
  );
};
