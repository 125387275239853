import { useState } from 'react';
import { AddToCart } from './variants/AddToCart';
import { actions } from '../../context/actions';
import { useAppContext } from '../../context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { AddPositionEnum } from '../../types/config';
import { ModalTypes } from '../Modals/types';
import IconButton from '@mui/material/IconButton';
import styled from '@emotion/styled';
import React from 'react';
import { IProduct } from '../../types';
import { dynataAddToCart } from '../../helpers';

export const AddToCartButton = ({ product, url }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [newAmount, setNewAmount] = React.useState(0);

  const {
    dispatch,
    value: { cart },
  } = useAppContext();
  const productInCart = cart.find(
    (productInCart: IProduct) => product.id === productInCart?.id
  );
  React.useEffect(() => {
    const amount = productInCart?.amount || 1;
    setNewAmount(amount);
  }, [cart, product]);

  const openModal = () => {
    setIsLoading(false);
    dispatch({
      type: actions.TOGGLE_MODAL,
      payload: {
        isModal: true,
        modalType: ModalTypes.choseOptions,
        modalContent: {
          product: product,
        },
      },
    });
  };

  const handleAddToCart = (amount = newAmount) => {
    setIsLoading(true);
    if (amount > 0) {
      dispatch({
        type: actions.ADD_TO_CART,
        payload: {
          ...product,
          newAmount: amount,
        },
      });
      dynataAddToCart(url + '_cartadd' + amount);
    } else {
      dynataAddToCart(url + '_cartadd' + amount);
      dispatch({
        type: actions.REMOVE_FROM_CART,
        payload: { type: 'REMOVE_ALL', product },
      });
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const handleQuantityChange = (product: any, quantity: any) => {
    setNewAmount(quantity);
    setTimeout(() => {
      handleAddToCart(quantity);
    }, 800);
  };

  const openQuantitySelectModal = () => {
    dispatch({
      type: actions.TOGGLE_MODAL,
      payload: {
        isModal: true,
        modalType: ModalTypes.quantity,
        product,
        modalContent: {
          handleQuantityChange: (product: any, newAmount: any) =>
            handleQuantityChange(product, newAmount),
          newAmount,
        },
      },
    });
  };
  const handleOpenModalOrAddToCart = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setIsLoading(true);
    if (product.attributes && product.attributes.length > 1) {
      setTimeout(openModal, 1000);
    } else if (productInCart) {
      openQuantitySelectModal();
    } else {
      setTimeout(handleAddToCart, 1000);
    }
  };

  return (
    <AddToCart
      handleAddToCartChange={handleOpenModalOrAddToCart}
      buttonTitle={'Add to Cart'}
      isLoading={isLoading}
      product={product}
    />
  );
};

export const AddButton = styled.button<{ AddPosition?: AddPositionEnum }>``;

export const StepperComponent = ({
  isTextAndStepper,
  handleAddToCartChange,
  newAmount,
  handleIncreaseByOne,
  handleDecreaseByOne,
}: any) => {
  return (
    <>
      <IconButton
        sx={{
          color: '#fff',
          border: '1px solid #fff',
          padding: '3px',
          marginRight: '3px',
        }}
        size="small"
        onClick={(e) => {
          e.preventDefault();
          if (isTextAndStepper) {
            handleIncreaseByOne();
          } else {
            handleAddToCartChange('minus');
          }
        }}
      >
        <FontAwesomeIcon className="expand" icon={faMinus} />
      </IconButton>
      <span>{newAmount}</span>
      <IconButton
        sx={{
          color: '#fff',
          border: '1px solid #fff',
          padding: '3px',
          marginLeft: '3px',
        }}
        size="small"
        onClick={(e) => {
          e.preventDefault();
          if (isTextAndStepper) {
            handleDecreaseByOne();
          } else {
            handleAddToCartChange('plus');
          }
        }}
      >
        <FontAwesomeIcon className="expand" icon={faPlus} />
      </IconButton>
    </>
  );
};
