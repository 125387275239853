import { css } from '@emotion/react';
import { TargetIcon } from '../../../assets/icons/TargetIcon';
import { theme } from '../../../theme';
import footer from '../../../assets/images/homescreen/footer.png';
import styled from '@emotion/styled';

export const HomeFooterSection = () => (
  <StyledComponentWrapper>
    <StyledImageContainer>
      <StyledImage src={footer} alt="footer" />
    </StyledImageContainer>
    <StyledContentOverlay>
      <TargetIcon scale={2} active />
      <TargetText>target</TargetText>
      <SloganText>Expect More. Pay Less.</SloganText>
    </StyledContentOverlay>
  </StyledComponentWrapper>
);

const StyledComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  margin-top: 100px;
`;

const StyledImageContainer = styled.div`
  overflow: hidden;
  width: 100%;
`;

const StyledImage = styled.img`
  height: auto;
  transform-origin: bottom;
  width: 100%;
  transform: scale(1.4);
`;

const StyledContentOverlay = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 5%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
`;

const TextStyle = css`
  font-size: 16px;
  font-weight: 800;
  color: ${theme.target.primary};
`;

const TargetText = styled.p`
  ${TextStyle};
  margin-top: 16px;
`;

const SloganText = styled.p`
  ${TextStyle};
  width: 100%;
  margin-top: 10px;
  color: ${theme.target.black};
`;
