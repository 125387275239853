import React from 'react';
import { useNavigate } from 'react-router-dom';
import { normalizeNameForUrl } from '../../helpers';
import { VariantContainer } from './styles';

export const ProductVariants = ({
  product,
  storeId,
  harvestId,
  categoryId,
}: any) => {
  const navigate = useNavigate();

  return (
    <VariantContainer>
      {product?.Attribute.map((attribute: any) => (
        <div className="variants-inner-container" key={attribute.AttrName}>
          <p>
            <span>{attribute.AttrName}</span>
            <span className="attribute-value">
              {' - ' + attribute.AttrValue}
            </span>
          </p>
          {product?.Variants && product.Variants.length > 1 && (
            <div className="variants">
              {product.Variants.map((variant: any) => {
                const isSelected = product?.id === variant?.id;
                const brand = variant?.Brand ? variant?.Brand : '';
                const variantClasses = isSelected
                  ? 'variant active'
                  : 'variant';
                const onClickHandler = () => {
                  navigate(
                    categoryId
                      ? `/${storeId}/${harvestId}/ip/${categoryId}/${
                          variant?.id
                        }__${brand}__${normalizeNameForUrl(
                          variant?.Name
                        )}_${variant?.RegularPrice.toFixed(2)}`
                      : `/${storeId}/${harvestId}/ips/${
                          variant?.id
                        }__${brand}__${normalizeNameForUrl(
                          variant?.Name
                        )}_${variant?.RegularPrice.toFixed(2)}`
                  );
                };
                const key = `${variant.id}-${
                  variant.Brand
                }-${normalizeNameForUrl(variant.Name)}`;
                const attributeValue =
                  variant?.Attribute.find(
                    (attr: any) => attr.AttrName === attribute.AttrName
                  )?.AttrValue || '';

                return (
                  <div
                    className={variantClasses}
                    key={key}
                    onClick={onClickHandler}
                  >
                    <span className="variant-label">{attributeValue}</span>
                  </div>
                );
              })}
            </div>
          )}
          <hr />
        </div>
      ))}
    </VariantContainer>
  );
};
