import React from 'react';

import { GET_HARVEST_TERMS, GET_PRODUCT_CATEGORY_BY_TERM } from '../../queries';
import { useQuery } from '@apollo/client';
import { useAppContext } from '../../context';
import { useNavigate, useParams } from 'react-router-dom';

export const useNavigation = () => {
  const navigate = useNavigate();

  const {
    value: { cart },
  } = useAppContext();

  const { storeId = '', harvestId = '', termId = '' } = useParams();

  const [totalCartValue, setTotalCartValue] = React.useState(0);
  const [searchKeyword, setSearchKeyword] = React.useState<string>('');
  const [suggestionsToRender, setSuggestionsToRender] = React.useState([]);
  const [suggestionsVisible, setSuggestionsVisible] = React.useState(false);

  const { data: nameData, loading: nameLoading } = useQuery(
    GET_PRODUCT_CATEGORY_BY_TERM({ termId }),
    {
      skip: !termId,
    }
  );

  const { data: suggestions, loading } = useQuery(GET_HARVEST_TERMS, {
    variables: {
      fullpath: `/ePog Product Data/Terms/${storeId}/SERP/${harvestId}`,
    },
  });

  const handleLogoClick = () => navigate(`/${storeId}/${harvestId}`);

  const handleSearchSubmit = (value: string) => {
    setSuggestionsVisible(false);
    navigate(`/${storeId}/${harvestId}/search/sk=${value}`);
  };

  const handleSearchSuggestions = (value: string) => {
    setSuggestionsVisible(true);
    setSearchKeyword(value);
  };

  const handleSuggestionClick = (id: string, name: string) => {
    setSearchKeyword(name);
    setSuggestionsVisible(false);
    navigate(
      `/${storeId}/${harvestId}/search/${id}__${name.replaceAll(' ', '_')}`
    );
  };

  const handleCartClick = () => navigate(`/${storeId}/${harvestId}/cart`);

  React.useEffect(() => {
    if (searchKeyword?.length === 0) {
      setSuggestionsToRender([]);
    }

    if (!loading && searchKeyword.length > 0) {
      let array: any = [];

      const flatChildren = (arr: [any]) => {
        arr?.forEach((item: any) => {
          array.push(item);
          if (item?.children?.length) {
            flatChildren(item?.children);
          }
        });
      };

      flatChildren(suggestions?.getHarvest?.children);

      const suggestedTerms = array
        .map((item: any) => item)
        .filter((item: any) =>
          item?.key?.toLowerCase().includes(searchKeyword?.toLowerCase())
        );

      setSuggestionsToRender(suggestedTerms);
    }
  }, [searchKeyword, loading, suggestions]);

  React.useEffect(() => {
    const totalValue = cart
      .reduce((prev: any, current: any) => {
        const price = current.Price ? current.Price : current.RegularPrice;
        let totalAmount;
        if (current.PromoAmount && current.amount >= current.PromoAmount) {
          totalAmount =
            Math.floor(current.amount / current.PromoAmount) *
              current.PromoCount *
              price +
            (current.amount % current.PromoAmount) * price;
        } else {
          totalAmount = current.amount * price;
        }
        return totalAmount + prev;
      }, 0)
      .toFixed(2);
    setTotalCartValue(totalValue);
  }, [cart]);

  React.useEffect(() => {
    if (!nameLoading) {
      const name = nameData?.getEPogTermListing?.edges[0]?.node?.key;
      setSearchKeyword(name || '');
    }
  }, [nameData, nameLoading]);

  React.useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data.message == 'buttonClick') {
        window.finishIt = true;
        window.triggerPath = window.location.pathname;
        navigate(`/${storeId}/${harvestId}/cart`);
      }
    });
  }, []);

  return {
    filteredSuggestions: suggestionsToRender,
    handleLogoClick,
    handleSearchSubmit,
    handleSearchSuggestions,
    handleSuggestionClick,
    handleCartClick,
    totalCartValue,
    suggestionsVisible,
    searchKeyword,
  };
};
