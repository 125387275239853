import { TargetIcon } from '../../../assets/icons/TargetIcon';
import { RiHomeHeartLine } from 'react-icons/ri';
import { HomeCommitmentsButton } from './HomeCommitmentsButton';
import { theme } from '../../../theme';
import styled from '@emotion/styled';

export const HomeCommitmentsSection = () => {
  const buttonData = [
    {
      icon: <TargetIcon scale={1} color={theme.target.white} />,
      iconText: 'forward',
      title: 'Target Forward',
      details:
        "Let's move toward a more equitable & regenerative future together. Explore our sustainability strategy.",
    },
    {
      icon: <RiHomeHeartLine fontSize={'30px'} color={theme.target.white} />,
      iconText: 'commit',
      title: 'Our commitments',
      details: (
        <>
          Learn about our commitments to
          <br />
          ethics, our team, our communities, and more.
        </>
      ),
    },
  ];

  return (
    <StyledComponentWrapper>
      <StyledSectionTitle>Our corporate commitments</StyledSectionTitle>
      <StyledButtonsWrapper>
        {buttonData.map((button) => (
          <HomeCommitmentsButton
            key={button.title}
            mainContent={
              <>
                {button.icon}
                <StyledIcontext>{button.iconText}</StyledIcontext>
              </>
            }
            title={button.title}
            details={button.details}
          />
        ))}
      </StyledButtonsWrapper>
    </StyledComponentWrapper>
  );
};

const StyledComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 16px;
`;

const StyledIcontext = styled.p`
  margin-left: 8px;
  font-size: 28px;
  font-weight: 700;
  color: ${theme.target.white};
`;

const StyledSectionTitle = styled.p`
  display: flex;
  margin: 0px 0px 32px 16px;
  font-size: 18px;
  font-weight: 700;
`;
