import React from 'react';
import { IProduct } from '../../types';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from '../../context';
import { actions } from '../../context/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ButtonStyled, ButtonInCart, PdpCardStyled } from './styles';
import { useQuery } from '@apollo/client';
import { GET_LOCALIZED } from '../../queries';
import { PdpGallery } from '../PdpGallery';
import { Grow } from '@mui/material';
import { ExpantableComponent } from '../ExpantableComponent';
import { ProductVariants } from '../ProductVariants';
import ShippingMethods from '../ShippingMethods';
import { ModalTypes } from '../Modals/types';
import { PriceTagIcon } from '../../assets/icons/PriceTagIcon';
import { theme } from '../../theme';
import { LoadingSpinner } from '../../assets/LoadingSpinner';
import { useConfiguration } from '../../hooks/useConfiguration';
import { contentSquarePageEvent } from '../../helpers';

export const PdpCard = ({ product }: { product: IProduct }) => {
  const [newAmount, setNewAmount] = React.useState(0);
  const [prevKey, setPrevKey] = React.useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { storeId = '', categoryId, harvestId = '', productId } = useParams();
  const {
    dispatch,
    value: { cart },
  } = useAppContext();
  const { Currency, ShipmentMethod } = useConfiguration({
    storeId,
    harvestId,
  });

  const { LanguageVersion } = useConfiguration({ storeId, harvestId });

  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });

  // let href = `${window.location.pathname}__${product.Name}`;
  let href = window.location.pathname;

  const price = product.Price != 0 ? product.Price : product.RegularPrice;
  const currency = Currency ? Currency : '$';

  const [shippingMethodSelected, setShippingMethodSelected] =
    React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const pricePerUnit =
    product.PricePerUnit && product.PricePerUnit != '0'
      ? ' (' + product.PricePerUnit + ')'
      : '';

  const productInCart = cart.find(
    (productInCart: IProduct) => product.id === productInCart?.id
  );

  const productAmount = productInCart ? productInCart.amount : 0;

  React.useEffect(() => {
    const amount = productInCart?.amount || 1;
    setNewAmount(amount);
  }, [cart, product]);

  React.useEffect(() => {
    if (
      location.state?.prevPath.split('_cartadd')[0] ===
      location.pathname.split('_cartadd')[0]
    ) {
      setPrevKey(location.state.prevKey);
    }
  }, [location]);
  React.useEffect(() => {
    setPrevKey(location.key);
  }, []);

  const handleAddToCart = (newAmount: any) => {
    const navState = { prevPath: location.pathname, prevKey: prevKey };
    setIsLoading(true);
    if (newAmount > 0 && href.indexOf('_cartadd') === -1) {
      dispatch(
        {
          type: actions.ADD_TO_CART,
          payload: {
            ...product,
            newAmount,
            shippingMethod: shippingMethodSelected,
          },
        },
        navigate(href + '_cartadd' + newAmount, {
          replace: true,
          state: navState,
        })
      );
    } else {
      dispatch(
        {
          type: actions.ADD_TO_CART,
          payload: {
            ...product,
            newAmount,
            shippingMethod: shippingMethodSelected,
          },
        },
        contentSquarePageEvent('addToCart'),
        navigate(href.replace(/_cartadd\d?/, '') + '_cartadd' + newAmount, {
          replace: true,
          state: navState,
        })
      );
    }

    if (newAmount === 0) {
      dispatch({
        type: actions.REMOVE_FROM_CART,
        payload: { type: 'REMOVE_ALL', product },
      });
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 800);
  };
  const handleQuantityChange = (product: any, quantity: any) => {
    setNewAmount(quantity);
    handleAddToCart(quantity);
  };

  const openQuantitySelectModal = () => {
    dispatch({
      type: actions.TOGGLE_MODAL,
      payload: {
        isModal: true,
        modalType: ModalTypes.quantity,
        modalContent: {
          handleQuantityChange: (product: any, newAmount: any) =>
            handleQuantityChange(product, newAmount),
          newAmount,
        },
      },
    });
  };

  const [showStickyAddToCart, setShowStickyAddToCart] = React.useState(false);
  React.useEffect(() => {
    const addToCartButton = document.querySelector('.addToCartButton');
    const toggleVisibility = () => {
      if (!addToCartButton) return;
      const rect = addToCartButton.getBoundingClientRect();
      setShowStickyAddToCart(rect.bottom < window.innerHeight * 0.2);
    };
    toggleVisibility();
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);
  return (
    <PdpCardStyled>
      <p>{product.Name}</p>
      {product.RRAmount !== 0 ? (
        <div className="rating">
          {product.RROverall && (
            <Stack spacing={1}>
              <Rating
                name="half-rating-read"
                defaultValue={product.RROverall}
                precision={0.5}
                readOnly
              />
            </Stack>
          )}
          {product.RRAmount && <span>({product.RRAmount})</span>}
        </div>
      ) : (
        <div className="rating">
          <Stack spacing={1}>
            <Rating
              name="half-rating-read"
              defaultValue={0}
              precision={0.5}
              readOnly
            />
          </Stack>
          <span>(0)</span>
        </div>
      )}
      <div className="pdp-gallery">
        <PdpGallery product={product} />
        <div className="heart-icon-container">
          <FontAwesomeIcon icon={farHeart} />
        </div>
      </div>
      <div className="price-container">
        {price && (
          <div className="price">
            {product.Price !== 0 ? (
              <div>
                {currency}
                {product.Price.toFixed(2)}{' '}
                <span className="oldPrice">
                  {currency}
                  {product.RegularPrice.toFixed(2)}
                </span>
              </div>
            ) : (
              <div>
                {currency}
                {product.RegularPrice.toFixed(2)}
              </div>
            )}
            <span className="pricePerUnit">{pricePerUnit}</span>
          </div>
        )}
        <span>When purchased online </span>
        <span>
          <FontAwesomeIcon icon={faInfoCircle} />
        </span>
      </div>
      <hr></hr>
      {product?.Attribute[0].AttrName &&
        product?.Attribute[0].AttrValue !== 'value' && (
          <ProductVariants
            product={product}
            storeId={storeId}
            harvestId={harvestId}
            categoryId={categoryId}
          />
        )}

      <div className="attributes">
        <ExpantableComponent
          title="Available deals"
          fontSize="18px"
          arrowPosition="right"
        >
          <div className="deal-content">
            <div className="deal-icon">
              <PriceTagIcon scale="2" />
            </div>
            <div>
              <p>
                Target Circle Coupon: $1 off
                <span>Eligible with target circle membership</span>
              </p>
            </div>
          </div>
        </ExpantableComponent>
      </div>

      <div className="shipping-container">
        <ShippingMethods
          shippingMethodSelected={shippingMethodSelected}
          setShippingMethodSelected={setShippingMethodSelected}
          storeId={storeId}
          harvestId={harvestId}
        />
      </div>

      <div className="addToCartButton">
        {isLoading ? (
          <ButtonStyled variant="contained">
            <LoadingSpinner height={25} width={25} color={theme.target.grey} />
          </ButtonStyled>
        ) : !isLoading && productInCart?.amount > 0 ? (
          <Grow in={!isLoading && productAmount > 0}>
            <ButtonInCart variant="contained" onClick={openQuantitySelectModal}>
              {productAmount} in cart
              <span>
                <FontAwesomeIcon className="faIcon" icon={faChevronDown} />
              </span>
            </ButtonInCart>
          </Grow>
        ) : (
          <ButtonStyled
            variant="contained"
            onClick={() => handleAddToCart(newAmount)}
          >
            {translation?.getLocalization.AddToCart}
          </ButtonStyled>
        )}
      </div>
      {showStickyAddToCart && (
        <div className="stickyAddToCart">
          {isLoading ? (
            <ButtonStyled variant="contained">
              <LoadingSpinner
                height={25}
                width={25}
                color={theme.target.grey}
              />
            </ButtonStyled>
          ) : !isLoading && productInCart?.amount > 0 ? (
            <Grow in={!isLoading && productAmount > 0}>
              <ButtonInCart
                variant="contained"
                onClick={openQuantitySelectModal}
              >
                {productAmount} in cart
                <span>
                  <FontAwesomeIcon className="faIcon" icon={faChevronDown} />
                </span>
              </ButtonInCart>
            </Grow>
          ) : (
            <ButtonStyled
              variant="contained"
              onClick={() => handleAddToCart(newAmount)}
            >
              {translation?.getLocalization.AddToCart}
            </ButtonStyled>
          )}
        </div>
      )}
    </PdpCardStyled>
  );
};
