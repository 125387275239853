import React from 'react';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { FilterModalCommonStyles } from '../commonStyles';
import styled from '@emotion/styled';
import { FilterModalButtons } from '../../FilterModalButtons';
import { actions } from '../../../context/actions';
import { useAppContext } from '../../../context';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../hooks/useConfiguration';
import { GET_LOCALIZED } from '../../../queries';
import { useQuery } from '@apollo/client';
import { theme } from '../../../theme';
import { ModalTypes } from '../../Modals/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '@mui/material';

export const VariantsFilter = ({ buttonsOff, header, handleClose }: any) => {
  const {
    value: { searchFilters },
    dispatch,
  } = useAppContext();

  const variantsFilter = searchFilters.find(
    (filter: any) => filter.name === 'Variants'
  );

  const { storeId = '', termId, harvestId = '' } = useParams();

  const { LanguageVersion } = useConfiguration({ storeId, harvestId });

  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });

  const handleSubmit = () => {
    dispatch({
      type: actions.ADD_SEARCH_FILTER,
      payload: { name: 'Variants', value },
    });
    dispatch({
      type: actions.TOGGLE_MODAL,
      payload: {
        isModal: false,
        modalType: ModalTypes.filtersMenu,
      },
    });
    dispatch({
      type: actions.TOGGLE_MODAL,
      payload: {
        isModal: false,
        modalType: ModalTypes.variants,
      },
    });
  };

  const handleClear = () => {
    setValue(null);
    dispatch({
      type: actions.REMOVE_SEARCH_FILTER,
      payload: { name: 'Variants', value: null },
    });
  };

  const [value, setValue] = React.useState(variantsFilter?.value || '');

  return (
    <FilterModalCommonStyles buttonsOff={buttonsOff}>
      {header && (
        <HeaderStyled>
          <span>Price</span>
          <IconButtonStyled onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </IconButtonStyled>
        </HeaderStyled>
      )}
      <ComponentStyled>
        <FormControl>
          <RadioGroup onChange={(e) => setValue(e.target.value)} value={value}>
            <StyledFormControlLabel
              value="yes"
              control={<StyledRadio />}
              label={translation?.getLocalization.ShowProductWithVariants}
            />
            <StyledFormControlLabel
              value="no"
              control={<StyledRadio />}
              label={translation?.getLocalization?.ShowProductWithNoVariants 
                ? translation?.getLocalization?.ShowProductWithNoVariants 
                : "Show products with no variants"}
            />
          </RadioGroup>
        </FormControl>
        <ButtonsContainer>
          <FilterModalButtons onSubmit={handleSubmit} onClear={handleClear} />
        </ButtonsContainer>
      </ComponentStyled>
    </FilterModalCommonStyles>
  );
};

const ComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 83vh;

  .MuiFormControl-root {
    height: 100vh;
  }
  .MuiFormControlLabel-label {
    font-size: 20px;
  }
`;
const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 700;
  border-bottom: 1px solid ${theme.target.grey};
`;
const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 0;
  padding: 10px;
  border-bottom: 1px solid ${theme.target.grey};
  &&:first-of-type {
    border-top: 1px solid ${theme.target.grey};
  }
  .MuiRadio-colorSecondary.Mui-checked {
    color: ${theme.target.green};
    font-size: 24px;
  }
`;

const StyledRadio = styled(Radio)`
  &.Mui-checked {
    color: ${theme.target.green};
  }
  .MuiSvgIcon-root {
    font-size: 24px;
  }
`;
export const IconButtonStyled = styled(IconButton)`
  & > svg {
    color: ${theme.target.greyDark};
  }
`;
export const ButtonsContainer = styled.div`
  background-color: #fff;
  padding: 16px;
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 70px;
  z-index: 1000;
`;
