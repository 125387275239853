import { IoIosCloseCircleOutline } from 'react-icons/io';
import styled from '@emotion/styled';
import { useState } from 'react';
import { theme } from '../../theme';
import { IProduct } from '../../types';

export const QuantitySelectModal = ({
  isOpen,
  newAmount,
  onClose,
  handleQuantityChange,
  product,
}: any) => {
  const [isExiting, setIsExiting] = useState(false);
  const [updatedItemAmount, setUpdatedItemAmount] = useState(newAmount);
  const [activeQuantity, setActiveQuantity] = useState(newAmount);

  const closeModal = () => {
    setIsExiting(true);
    setTimeout(() => {
      setIsExiting(false);
      onClose();
    }, 500);
  };

  const handleQuantityChangeAndCloseModal = (
    product: IProduct,
    newAmount: number
  ) => {
    handleQuantityChange(product, newAmount);
    closeModal();
  };

  return (
    <ModalContainer>
      <ModalHeader>
        <p>Choose Quantity</p>
        <IoIosCloseCircleOutline
          onClick={closeModal}
          style={{ fontSize: '40px', cursor: 'pointer' }}
        />
      </ModalHeader>
      <ModalBody>
        {[...Array(11)].map((_, index) => (
          <ModalItem
            key={index}
            active={activeQuantity === index}
            onClick={() => {
              setUpdatedItemAmount(index);
              setActiveQuantity(index);
            }}
            theme={theme}
          >
            {index}
          </ModalItem>
        ))}
      </ModalBody>
      <ModalButton
        onClick={() =>
          handleQuantityChangeAndCloseModal(product, updatedItemAmount)
        }
        color="primary"
      >
        Update quantity
      </ModalButton>
    </ModalContainer>
  );
};

const ModalContainer = styled.div`
  padding: 16px;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${theme.target.grey};

  p {
    font-size: 16px;
    font-weight: 600;
  }
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  max-height: 250px;
  gap: 10px;
  z-index: 4000;
  padding: 40px 0px;
`;

const ModalItem = styled.p<{ active: boolean; theme: any }>`
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  padding: 4px;
  color: ${({ active, theme }: any) =>
    active ? theme.target.black : theme.target.grey};
  background-color: ${({ active, theme }: any) =>
    active ? theme.target.greyVeryLight : 'inherit'};
`;

const ModalButton = styled.button`
  border: none;
  padding: 12px;
  width: 100%;
  margin-top: 24px;
  font-size: 20px;
  border-radius: 8px;
  margin-bottom: 8px;
  background-color: ${theme.target.primary};
  color: ${theme.target.white};
`;
