import { theme } from '../../../../theme';
import cartImage from '../../../../assets/images/cart/cartImage.png';
import styled from '@emotion/styled';

export const CartEmptySection = () => {
  return (
    <EmptyCartWrapper>
      <EmptyCartImageWrapper>
        <EmptyCartImageContainer>
          <img src={cartImage} alt="product" />
        </EmptyCartImageContainer>
      </EmptyCartImageWrapper>
      <EmptyCartSavedItemsLinkContainer>
        <p>View saved items</p>
      </EmptyCartSavedItemsLinkContainer>
      <EmptyCartBottom />
    </EmptyCartWrapper>
  );
};

const EmptyCartWrapper = styled.div``;

const EmptyCartImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  background-color: ${theme.target.greyVeryLight};
`;

const EmptyCartImageContainer = styled.div`
  background-color: ${theme.target.greyLight};
  height: 240px;
  width: 240px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 180px;
  }
`;

const EmptyCartSavedItemsLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  background-color: ${theme.target.white};

  p {
    color: ${theme.target.steelblue};
    font-size: 16px;
    font-weight: 600;
  }
`;

const EmptyCartBottom = styled.div`
  height: 30vh;
  background-color: ${theme.target.greyVeryLight};
`;
