import React from 'react';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled from '@emotion/styled';

type Tcolors = string | (() => string);

interface IProps {
  title: string;
  onClick: () => void;
  isExpand?: boolean;
  addinitonalIcon?: React.ReactNode;
  colors?: Tcolors;
  background: Tcolors;
}

export const Chip = ({
  title,
  onClick,
  isExpand,
  addinitonalIcon,
  colors,
  background,
}: IProps) => {
  return (
    <ChipStyled onClick={onClick} colors={colors} background={background}>
      {addinitonalIcon && (
        <span className="additional-icon">{addinitonalIcon}</span>
      )}
      {title}
      {isExpand && <FontAwesomeIcon className="expand" icon={faChevronDown} />}
    </ChipStyled>
  );
};

const ChipStyled = styled.span<{ colors?: Tcolors; background?: Tcolors }>`
  background: ${({ background, colors }) =>
    colors === 'dark' ? '#DBDBDB' : background};
  color: #000;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;

  cursor: pointer;

  .additional-icon {
    display: flex;
    align-items: center;
    margin-right: 6px;

    div {
      display: flex;
      align-items: center;
    }

    svg {
      height: 15px;
      fill: #000;
      stroke: #000;
    }
  }
  .expand {
    margin-left: 7px;
  }
`;
