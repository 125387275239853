import React from 'react';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import { ModalTypes } from './types';
import { useAppContext } from '../../context';
import { FiltersMenu } from '../FiltersMenu';
import { actions } from '../../context/actions';
import { useConfiguration } from '../../hooks/useConfiguration';
import { BrandsFilterModal } from '../Filters/BrandsFilter';
import { CategoriesFilterModal } from '../Filters/CategoriesFilter';
import { VariantsFilter } from '../Filters/VariantsFilter';
import { SpecialOffersFilterModal } from '../Filters/SpecialOffersFilter';
import { PriceRangeFilter } from '../Filters/PriceRangeFilter';
import { Sorting } from '../Filters/Sorting';
import { RatingFilter } from '../Filters/RatingFilter';
import { QuantitySelectModal } from '../QuantitySelect';
import { ChoseOptions } from '../ChoseOptions';
import { Slide, SlideProps } from '@mui/material';

export const ModalComponent = () => {
  const {
    value: { modals },
    dispatch,
  } = useAppContext();

  const { storeId = '', harvestId = '' } = useParams();

  const { FilterPosition } = useConfiguration({ storeId, harvestId });

  const handleClose = (type: any) => () => {
    dispatch({
      type: actions.TOGGLE_MODAL,
      payload: { isModal: false, modalType: type },
    });
  };

  const modalSelector = (modalType: any, modalContent: any, isModal: any) => {
    const handleCloseForModal = handleClose(modalType);
    switch (modalType) {
      case ModalTypes.filtersMenu:
        return (
          <FiltersMenu
            productsBeforeFiltering={modalContent.productsBeforeFiltering}
            categoryName={modalContent.categoryName}
            isModal={isModal}
            handleClose={handleCloseForModal}
          />
        );

      case ModalTypes.priceRange:
        return (
          <PriceRangeFilter
            productsBeforeFiltering={modalContent.productsBeforeFiltering}
            header={true}
            handleClose={handleCloseForModal}
          />
        );

      case ModalTypes.brands:
        return (
          <BrandsFilterModal
            productsBeforeFiltering={modalContent.productsBeforeFiltering}
            header={true}
            handleClose={handleCloseForModal}
          />
        );

      case ModalTypes.sorting:
        return <Sorting header={true} handleClose={handleCloseForModal} />;

      case ModalTypes.rating:
        return <RatingFilter header={true} handleClose={handleCloseForModal} />;

      case ModalTypes.variants:
        return (
          <VariantsFilter header={true} handleClose={handleCloseForModal} />
        );

      case ModalTypes.specialOffers:
        return (
          <SpecialOffersFilterModal
            productsBeforeFiltering={modalContent.productsBeforeFiltering}
            handleClose={handleCloseForModal}
          />
        );

      case ModalTypes.categories:
        return (
          <CategoriesFilterModal
            productsBeforeFiltering={modalContent.productsBeforeFiltering}
            header={true}
            handleClose={handleCloseForModal}
          />
        );

      case ModalTypes.quantity:
        return (
          <QuantitySelectModal
            newAmount={modalContent.newAmount}
            handleQuantityChange={modalContent.handleQuantityChange}
            isOpen={isModal}
            onClose={handleCloseForModal}
          />
        );

      case ModalTypes.choseOptions:
        return (
          <ChoseOptions
            product={modalContent.product}
            handleClose={handleCloseForModal}
            productAmount={modalContent.productAmount}
            isOpen={isModal}
          />
        );

      default:
        return false;
    }
  };

  return modals.map((modal: any, index: any) => (
    <DialogStyled
      key={index}
      open={modal.isVisible}
      onClose={handleClose(modal.modalType)}
      FilterPosition={FilterPosition}
      TransitionComponent={Transition}
      modalType={modal.modalType}
    >
      {modalSelector(modal.modalType, modal.modalContent, modal.isVisible)}
    </DialogStyled>
  ));
};
const Transition = React.forwardRef(function Transition(
  props: SlideProps,
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogStyled = styled(Dialog, {
  shouldForwardProp(propName) {
    return propName !== 'modalType' && propName !== 'FilterPosition';
  },
})<{
  modalType: ModalTypes;
  FilterPosition?: string;
}>`
  .MuiDialog-root {
    position: fixed;
    width: 100%;
    height: ${({ modalType }) =>
      modalType === ModalTypes.quantity ? '500px' : 'auto'};
    margin: 0;
    z-index: ${({ modalType }) =>
      modalType === ModalTypes.quantity ? '1500' : '1300'};
  }

  .MuiDialog-container {
    display: flex;
    justify-content: ${({ modalType, FilterPosition }) => {
      if (modalType === ModalTypes.filtersMenu) {
        if (FilterPosition === 'Left') {
          return 'flex-start';
        } else {
          return 'flex-end';
        }
      }

      return 'center';
    }};
    align-items: flex-end;
  }

  .MuiDialog-paper {
    width: 100%;
    margin: 0;
    height: ${({ modalType }) =>
      modalType === ModalTypes.filtersMenu && '90vh'};
    margin: ${({ modalType }) => modalType === ModalTypes.filtersMenu && '0'};
    border-radius: 20px 20px 0px 0px;
  }

  .MuiBackdrop-root {
    display: ${({ modalType }) =>
      modalType === ModalTypes.quantity ? 'none' : 'block'};
  }
`;
