import React from 'react';

import styled from '@emotion/styled';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import { useAppContext } from '../../../context';
import { actions } from '../../../context/actions';
import { FilterModalCommonStyles } from '../commonStyles';
import { FilterModalButtons } from '../../FilterModalButtons';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../hooks/useConfiguration';
import { GET_LOCALIZED } from '../../../queries';
import { useQuery } from '@apollo/client';
import { theme } from '../../../theme';
import { ModalTypes } from '../../Modals/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '@mui/material';

export const Sorting = ({ buttonsOff, header, handleClose }: any) => {
  const [value, setValue] = React.useState('');

  const { storeId = '', harvestId = '' } = useParams();

  const { LanguageVersion } = useConfiguration({ storeId, harvestId });

  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });

  const {
    dispatch,
    value: { productsSorting },
  } = useAppContext();

  const handleSubmit = () => {
    value?.length &&
      dispatch({
        type: actions.ADD_PRODUCTS_SORTING,
        payload: { value },
      });
    dispatch({
      type: actions.TOGGLE_MODAL,
      payload: {
        isModal: false,
        modalType: ModalTypes.filtersMenu,
      },
    });
    dispatch({
      type: actions.TOGGLE_MODAL,
      payload: {
        isModal: false,
        modalType: ModalTypes.sorting,
      },
    });
  };

  const handleClearSorting = () => {
    dispatch({
      type: actions.REMOVE_PRODUCTS_SORTING,
    });
  };

  React.useEffect(() => {
    const initialSortingValue =
      productsSorting && productsSorting[0]?.value
        ? productsSorting[0].value
        : '';
    setValue(initialSortingValue);
  }, [productsSorting]);

  return (
    <FilterModalCommonStyles buttonsOff={buttonsOff}>
      {header && (
        <HeaderStyled>
          <span>Sort by</span>
          <IconButtonStyled onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </IconButtonStyled>
        </HeaderStyled>
      )}
      <ComponentStyled>
        <SortComponentStyled>
          <div className="sort-list-container">
            <div className="sort-list">
              <FormControl>
                <RadioGroup
                  onChange={(e) => setValue(e.target.value)}
                  value={value}
                >
                  <StyledFormControlLabel
                    value="Alphabet:A>Z"
                    control={<StyledRadio />}
                    label={translation?.getLocalization.AlphabetAZ}
                  />
                  <StyledFormControlLabel
                    value="Alphabet:Z>A"
                    control={<StyledRadio />}
                    label={translation?.getLocalization.AlphabetZA}
                  />
                  <StyledFormControlLabel
                    value="Price:low>high"
                    control={<StyledRadio />}
                    label={translation?.getLocalization.PriceLH}
                  />
                  <StyledFormControlLabel
                    value="Price:high>low"
                    control={<StyledRadio />}
                    label={translation?.getLocalization.PriceHL}
                  />
                  <StyledFormControlLabel
                    value="Rating:low>high"
                    control={<StyledRadio />}
                    label={translation?.getLocalization.RatingLH}
                  />
                  <StyledFormControlLabel
                    value="Rating:high>low"
                    control={<StyledRadio />}
                    label={translation?.getLocalization.RatingHL}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </SortComponentStyled>
        <ButtonsContainer>
          <FilterModalButtons
            onSubmit={handleSubmit}
            onClear={handleClearSorting}
          />
        </ButtonsContainer>
      </ComponentStyled>
    </FilterModalCommonStyles>
  );
};

const ComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 83vh;
`;
const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  font-size: 1.5rem;
  border-bottom: 1px solid ${theme.target.grey};
`;
export const IconButtonStyled = styled(IconButton)`
  & > svg {
    color: ${theme.target.greyDark};
  }
`;

const SortComponentStyled = styled.div`
  flex: 1;
  overflow: auto;

  .sort-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
  }
  .sort-list-container {
    flex: 1;
    overflow: auto;
  }
  .sort-list {
    width: 100%;
    .MuiFormControl-root {
      width: 100%;
    }
    .MuiFormControlLabel-label {
      font-size: 20px;
    }
  }
  svg {
    font-size: 24px;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  border-bottom: 1px solid ${theme.target.grey};
  margin: 0;
  padding: 10px;
  .MuiRadio-colorSecondary.Mui-checked {
    color: ${theme.target.green};
    font-size: 24px;
  }
`;

const StyledRadio = styled(Radio)`
  &.Mui-checked {
    color: ${theme.target.green};
  }
`;
export const ButtonsContainer = styled.div`
  background-color: ${theme.target.white};
  padding: 16px;
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 70px;
  z-index: 1000;
`;
