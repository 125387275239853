import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { IProduct } from '../../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FilterModalCommonStyles } from '../commonStyles';
import { FilterModalButtons } from '../../FilterModalButtons';
import {
  SpecialOffersStyled,
  HeaderStyled,
  ContentStyled,
  ButtonsContainer,
  IconButtonStyled,
} from './styles';
import { useSpecialOffersFilter } from './useSpecialOffersFilter';
import { useConfiguration } from '../../../hooks/useConfiguration';
import { GET_LOCALIZED } from '../../../queries';
import { CustomCheckbox } from '../../SucessCheckbox';

export const SpecialOffersFilterModal = ({
  productsBeforeFiltering,
  buttonsOff,
  header,
  handleClose,
}: any) => {
  const {
    searchBar,
    setSearchBarInput,
    handleSubmit,
    handleClear,
    handleCheckboxes,
    checked,
  } = useSpecialOffersFilter({ productsBeforeFiltering });

  const { storeId = '', termId, harvestId = '' } = useParams();

  const { LanguageVersion } = useConfiguration({ storeId, harvestId });

  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });

  return (
    <FilterModalCommonStyles buttonsOff={buttonsOff}>
      {header && (
        <HeaderStyled>
          <span>Special Offers</span>
          <IconButtonStyled onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </IconButtonStyled>
        </HeaderStyled>
      )}
      <SpecialOffersStyled>
        <ContentStyled>
          <div className="checkboxes">
            <FormGroup>
              {productsBeforeFiltering ? (
                productsBeforeFiltering
                  .filter(
                    (product: IProduct, index: number, arr: IProduct[]) =>
                      product.PromoType?.toLowerCase().includes(
                        searchBar.toLowerCase()
                      ) &&
                      index ===
                        arr.findIndex(
                          (item) => item.PromoType === product.PromoType
                        )
                  )
                  .map((product: IProduct, index: number) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          name={product.PromoType}
                          checked={checked[product.PromoType]}
                          onChange={() => handleCheckboxes(product.PromoType)}
                          color="success"
                          sx={{
                            '& .MuiSvgIcon-root': {
                              fontSize: 24,
                            },
                          }}
                        />
                      }
                      label={product.PromoType}
                    />
                  ))
              ) : (
                <div></div>
              )}
            </FormGroup>
          </div>
        </ContentStyled>
        <ButtonsContainer>
          <FilterModalButtons onSubmit={handleSubmit} onClear={handleClear} />
        </ButtonsContainer>
      </SpecialOffersStyled>
    </FilterModalCommonStyles>
  );
};
