import { theme } from '../../theme';
import { SvgIconProps } from '@mui/material';
interface ItargetIcon {
  scale?: number;
  color?: string;
  active?: boolean;
  props?: SvgIconProps;
}

export const TargetIcon = ({
  scale = 1,
  active = false,
  props,
}: ItargetIcon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    style={{ transform: `scale(${scale})` }}
    {...props}
  >
    <path
      fill={active ? theme.target.primary : theme.target.grey}
      d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0Zm0 19.826a7.827 7.827 0 1 0 0-15.652 7.827 7.827 0 0 0 0 15.652Zm0-3.985a3.839 3.839 0 0 1-1.475-7.394A3.84 3.84 0 0 1 12 8.16a3.84 3.84 0 0 1 0 7.68Z"
    />
  </svg>
);
