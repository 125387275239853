import { useEffect, useState } from 'react';
import { IProduct } from '../../../../types';
import { Modal } from '../cartModal/Modal';
import { CardDetailsSection } from './CardDetailsSection';
import { CardOptionsSection } from './CardOptionsSection';
import { theme } from '../../../../theme';
import { actions } from '../../../../context/actions';
import { useAppContext } from '../../../../context';
import { LoadingSpinner } from '../../../../assets/LoadingSpinner';
import styled from '@emotion/styled';
import { useCartHook } from '../../useCartHook';
import { VscTrash } from 'react-icons/vsc';
import { IoChevronDownSharp } from 'react-icons/io5';

export const CartProductCard = ({
  product,
  handleCart,
}: {
  product: IProduct;
  handleCart: any;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isChangingQty, setIsChangingQty] = useState(false);

  useEffect(() => {
    if (product?.amount) {
      setIsChangingQty(true);
      setTimeout(() => {
        setIsChangingQty(false);
      }, 700);
    }
  }, [product?.amount]);

  const openModal = (product: IProduct) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  const handleRemoveAllFromCart = () => {
    setIsLoading(true);
    handleClose();
    setTimeout(() => {
      setIsLoading(false);
      handleCart('removeAll', product);
    }, 1000);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const { dispatch } = useAppContext();

  const handleQuantityChange = (product: IProduct, newAmount: number) => {
    dispatch({
      type: actions.ADD_TO_CART,
      payload: {
        ...product,
        newAmount: newAmount,
      },
    });
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <StyledComponentWrapper isLoading={isLoading}>
      {isLoading && (
        <SpinnerWrapper>
          <LoadingSpinner width={40} height={40} color={theme.target.primary} />
        </SpinnerWrapper>
      )}
      <CardDetailsSection product={product} />

      <CardOptionsContainer>
        <CardOptionsQuantityButton onClick={() => openModal(product)}>
          {isChangingQty ? (
            <LoadingSpinner
              height={20}
              width={20}
              color={theme.target.primary}
            />
          ) : (
            <>
              <p>Qty {product?.amount}</p>
              <IoChevronDownSharp fontSize={12} color={theme.target.greyDark} />
            </>
          )}
        </CardOptionsQuantityButton>

        <RightContainer main={false}>
          <div
            onClick={handleRemoveAllFromCart}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '26px',
            }}
          >
            <RemoveItemParagraph>Remove</RemoveItemParagraph>
            <RemoveItemsIcon />
          </div>
        </RightContainer>
      </CardOptionsContainer>
      {showModal && (
        <>
          <Modal
            isOpen={showModal}
            onClose={closeModal}
            handleQuantityChange={handleQuantityChange}
            product={selectedProduct}
          />
        </>
      )}
    </StyledComponentWrapper>
  );
};

const StyledComponentWrapper = styled.div<{ isLoading: boolean }>`
  position: relative;
  padding: 16px 0px;
  border-bottom: 1px solid ${theme.target.greyLight};

  ${({ isLoading }) =>
    isLoading &&
    `
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.7); /* Adjust the transparency as needed */
      z-index: 1; /* Ensure the overlay is above other content */
      pointer-events: none; /* Allow interaction with elements beneath the overlay */
    }
  `}
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

const RightContainer = styled.div<{ main: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 100px;
  margin-right: 8px;
  background-color: ${({ main }) =>
    main ? theme.target.greyLight : 'transparent'};
`;

const CardOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CardOptionsQuantityButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  width: 100px;
  border: 2px solid lightgrey;

  p {
    font-size: 14px;
  }
`;

const RemoveItemParagraph = styled.p`
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  margin-left: 2px;
  color: ${theme.target.primary};
`;

const RemoveItemsIcon = styled(VscTrash)`
  margin-left: 6px;
  font-size: 18px;
  color: ${theme.target.primary};
`;
