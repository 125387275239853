import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { theme } from '../../theme';
export const ProductContainer = styled.div<{}>`
  padding: 20px 15px;
  max-width: 100vw;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  & > div:nth-of-type(1) {
    width: 40%;
    padding: 10px;
  }
`;

export const LinkStyled = styled(Link)`
  text-decoration: none;
  color: ${theme.target.black};
`;

export const DescriptionContainer = styled.div<{}>`
  width: 60%;
  margin-left: 10px;
  padding-right: 15px;

  .sponsored {
    color: ${theme.target.greyDark};
  }

  .limitedTimeDeal{
  margin-top: 10px;
  display: inline-block;
  padding: 6px;
  font-weight: 600;
  border-radius: 2px;
  background-color: ${theme.target.red.dark};
  color: ${theme.target.white};
  }

  .price {
    margin-top: 10px;
    font-size: 22px;
    font-weight: 600;
  }
  .oldPrice {
    margin-top: 5px;
    font-size: 13px;
    font-weight: 500;

    color:${theme.target.greyDark}
  }

  .name {
    font-weight: 500;
    font-size: 12px;
    display: -webkit-box;
    overflow: hidden;
    margin-top: 10px;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .rating {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    font-size: 12px;
    & > span {
      color: ${theme.target.greyDark};
      font-weight: 600;
      margin-right: 3px;
    }
  }
  .promo {
    margin: 10px 0;
    & > img {
      width: 100%;
    }
  }
`;
export const PriceTagStyled = styled.div`
  position: absolute;
  left: 0;
  display: flex;
  top: 50%;
  transform: translate(0, -50%);
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 15px;
  height: 15px;
  border: 2px solid #000;
  border-radius: 50%;
  margin: auto;
  border-color: ${theme.target.turquoise};
  & > svg {
    transform: scaleX(-1);
    width: 80%;
    height: auto;
    color: ${theme.target.red.dark};
  }
`;

export const DeliveryStyled = styled.p`
  margin-top: 10px;
  font-size: 13px;
  color: ${theme.target.greyDark};
`;
export const ButtonContainerStyled = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;

  & > svg {
    margin-left: 10px;
    width: 25px;
    height: auto;
    color: ${(props: any) =>
    !props.isActive ? theme.target.greyDark : theme.target.red.dark};
  }
`;
