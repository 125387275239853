import { theme } from '../../../theme';
import styled from '@emotion/styled';
interface IHomeCommitmentsButtonProps {
  mainContent: React.ReactNode;
  title: string;
  details: React.ReactNode;
}

export const HomeCommitmentsButton = ({
  mainContent,
  title,
  details,
}: IHomeCommitmentsButtonProps) => (
  <StyledComponentWrapper>
    <Container>{mainContent}</Container>
    <>
      <Title>{title}</Title>
      <Details>{details}</Details>
    </>
  </StyledComponentWrapper>
);

const StyledComponentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  flex-direction: column;
  height: 160px;
  width: 48%;
  border-radius: 12px;
  padding: 32px 16px 16px 16px;
  background-color: ${theme.target.primary};
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Title = styled.p`
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
  color: ${theme.target.white};
`;

const Details = styled.p`
  font-size: 8px;
  font-weight: 500;
  line-height: 1.4;
  color: ${theme.target.white};
`;
