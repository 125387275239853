interface ILoadingSpinner {
  width: number;
  height: number;
  color: string;
}

export const LoadingSpinner = ({ color, width, height }: ILoadingSpinner) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    preserveAspectRatio="xMidYMid"
    style={{
      shapeRendering: 'auto',
      display: 'block',
      background: '0 0',
    }}
    viewBox="0 0 100 100"
  >
    <rect width={10} height={22} x={47.5} y={19} fill={color} rx={0} ry={0}>
      <animate
        attributeName="opacity"
        begin="-0.875s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      />
    </rect>
    <rect
      width={10}
      height={22}
      x={47.5}
      y={19}
      fill={color}
      rx={0}
      ry={0}
      transform="rotate(45 50 50)"
    >
      <animate
        attributeName="opacity"
        begin="-0.75s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      />
    </rect>
    <rect
      width={10}
      height={22}
      x={47.5}
      y={19}
      fill={color}
      rx={0}
      ry={0}
      transform="rotate(90 50 50)"
    >
      <animate
        attributeName="opacity"
        begin="-0.625s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      />
    </rect>
    <rect
      width={10}
      height={22}
      x={47.5}
      y={19}
      fill={color}
      rx={0}
      ry={0}
      transform="rotate(135 50 50)"
    >
      <animate
        attributeName="opacity"
        begin="-0.5s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      />
    </rect>
    <rect
      width={10}
      height={22}
      x={47.5}
      y={19}
      fill={color}
      rx={0}
      ry={0}
      transform="rotate(180 50 50)"
    >
      <animate
        attributeName="opacity"
        begin="-0.375s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      />
    </rect>
    <rect
      width={10}
      height={22}
      x={47.5}
      y={19}
      fill={color}
      rx={0}
      ry={0}
      transform="rotate(225 50 50)"
    >
      <animate
        attributeName="opacity"
        begin="-0.25s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      />
    </rect>
    <rect
      width={10}
      height={22}
      x={47.5}
      y={19}
      fill={color}
      rx={0}
      ry={0}
      transform="rotate(270 50 50)"
    >
      <animate
        attributeName="opacity"
        begin="-0.125s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      />
    </rect>
    <rect
      width={10}
      height={22}
      x={47.5}
      y={19}
      fill={color}
      rx={0}
      ry={0}
      transform="rotate(315 50 50)"
    >
      <animate
        attributeName="opacity"
        begin="0s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      />
    </rect>
  </svg>
);
