import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import Button from '@mui/material/Button';
import { motion } from 'framer-motion';
import { VariantsFilter } from '../Filters/VariantsFilter';
import { BrandsFilterModal } from '../Filters/BrandsFilter';
import { FiltersMenuStyled } from './styles';
import { useFiltersMenu } from './useFiltersMenu';
import { IProduct } from '../../types';
import { Sorting } from '../Filters/Sorting';
import { PriceRangeFilter } from '../Filters/PriceRangeFilter';
import { RatingFilter } from '../Filters/RatingFilter';
import { useQuery } from '@apollo/client';
import { GET_LOCALIZED, GET_PRODUCT_CATEGORY_BY_TERM } from '../../queries';
import { useNavigate, useParams } from 'react-router-dom';
import { useConfiguration } from '../../hooks/useConfiguration';
import { Slide } from '@mui/material';
import { useAppContext } from '../../context';

export const FiltersMenu = ({
  productsBeforeFiltering,
  isModal,
  handleClose,
  categoryName,
}: any) => {
  const [activeFilter, setActiveFilter] = useState(null);

  const { isOpen, handleReset } = useFiltersMenu();
  const { storeId = '', harvestId = '', termId } = useParams();
  const { LanguageVersion } = useConfiguration({ storeId, harvestId });
  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: { defaultLanguage: LanguageVersion },
  });
  const { value } = useAppContext();
  const productCount = value.productLength;

  const filters = [
    { name: 'Sort By', component: <Sorting buttonsOff={false} /> },
    {
      name: 'Price',
      component: (
        <PriceRangeFilter
          buttonsOff={false}
          productsBeforeFiltering={productsBeforeFiltering}
        />
      ),
    },
    { name: 'Rating', component: <RatingFilter buttonsOff={false} /> },
    { name: 'Variants', component: <VariantsFilter buttonsOff={false} /> },
    {
      name: 'Brand',
      component: (
        <BrandsFilterModal
          buttonsOff={false}
          productsBeforeFiltering={productsBeforeFiltering}
        />
      ),
    },
  ];
  const { data: nameData, loading: nameLoading } = useQuery(
    GET_PRODUCT_CATEGORY_BY_TERM({ termId }),
    {
      skip: !termId,
    }
  );
  const name = nameData?.getEPogTermListing?.edges[0]?.node?.Name;

  const handleFilterSelect = (filterName: any) => {
    setActiveFilter(filterName);
  };

  const handleBackToFilters = () => {
    setActiveFilter(null);
  };

  const slideInAnimation = {
    hidden: { x: '100%' },
    show: {
      x: 0,
      transition: { type: 'spring', stiffness: 300, damping: 30 },
    },
    exit: {
      x: '100%',
      transition: { duration: 0.2 },
    },
  };
  const activeFilterComponent = filters.find((f) => f.name === activeFilter);

  return (
    <FiltersMenuStyled isInlineFilters={!!activeFilter}>
      <div className="filter-header">
        {activeFilter ? (
          <>
            <FontAwesomeIcon
              className="back-icon"
              icon={faArrowLeft}
              onClick={handleBackToFilters}
            />
            <span>{activeFilter}</span>
            <FontAwesomeIcon icon={faTimes} onClick={handleClose} />
          </>
        ) : (
          <>
            <span>All Filters</span>
            <FontAwesomeIcon icon={faTimes} onClick={handleClose} />
          </>
        )}
      </div>

      {activeFilter && activeFilterComponent ? (
        <Slide direction="left" in={true} unmountOnExit timeout={400}>
          <div>{activeFilterComponent.component}</div>
        </Slide>
      ) : null}
      {!activeFilter && (
        <div className="filters">
          {filters.map((filter, index) => (
            <div
              key={index}
              className="filter"
              onClick={() => handleFilterSelect(filter.name)}
            >
              <span>{filter.name}</span>
              <FontAwesomeIcon
                icon={isOpen[index] ? faArrowDown : faArrowRight}
              />
            </div>
          ))}
        </div>
      )}
      {!activeFilter && (
        <div className="filter-footer">
          <div>
            {productCount} result for {name}
          </div>
          <div className="button-container">
            <Button fullWidth className="reset-button" onClick={handleReset}>
              {translation?.getLocalization.Clear}
            </Button>
            <Button
              fullWidth
              variant="contained"
              className="confirm-button"
              onClick={handleClose}
            >
              {translation?.getLocalization.ViewResults}
            </Button>
          </div>
        </div>
      )}
    </FiltersMenuStyled>
  );
};
