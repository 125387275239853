import styled from '@emotion/styled';
import { theme } from '../../../../theme';
interface OrderSummaryItemProps {
  image: JSX.Element;
  header: string;
  subheader: string;
  icon: JSX.Element | string;
  string?: string | boolean | undefined;
}

export const OrderSummaryItem = ({
  image: Image,
  header,
  subheader,
  icon: Icon,
  string,
}: OrderSummaryItemProps) => {
  const isString = string === 'true';

  return (
    <ItemContainer>
      <ItemIcon leftIcon>
        <div style={{ paddingTop: '15px' }}>{Image}</div>
      </ItemIcon>
      <ItemInfo string={isString}>
        <p style={{ fontSize: '16px', fontWeight: 700 }}>{header}</p>
        {subheader !== '' && (
          <p style={{ fontSize: '12px', color: 'grey', fontWeight: 600 }}>
            {subheader}
          </p>
        )}
      </ItemInfo>
      <ItemIcon rightIcon string={isString}>
        {Icon}
      </ItemIcon>
    </ItemContainer>
  );
};

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0px 0px 8px 0px;
  background-color: ${theme.target.greyLight};
`;

const ItemInfo = styled.div<{ string?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  line-height: 1.2;
  flex: 1;
  margin-left: 8px;
  padding: 12px 0px;
  padding-bottom: 0px;

  p::nth-of-type(1) {
    font-size: 18px;
    font-weight: 700;
  }

  p::nth-of-type(2) {
    font-size: 12px;
    font-weight: 700;
    color: ${theme.target.greyDark};
  }
`;

const ItemIcon = styled.div<{
  leftIcon?: boolean;
  rightIcon?: boolean;
  string?: boolean;
}>`
  display: flex;
  align-items: center;
  width: 40px;
  height: 70px;
  font-size: ${({ string }) => (string ? '16px' : '25px')};
  padding: ${({ leftIcon }) =>
    leftIcon ? '0px 0px 0px 16px' : '12px 16px 12px 0px'};
  margin-left: ${({ leftIcon }) => (leftIcon ? '-3px' : '0px')};
  justify-content: ${({ leftIcon }) => (leftIcon ? 'flex-start' : 'flex-end')};
  color: ${({ string }) =>
    string ? `${theme.target.steelblue}` : ` ${theme.target.black}`};
  font-weight: ${({ string }) => (string ? 700 : 500)};
`;
