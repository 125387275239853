import { Drawer } from '@mui/material';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { IProduct } from '../../../../types';
import { useState } from 'react';
import { theme } from '../../../../theme';
import styled from '@emotion/styled';
import { Itheme } from '../../../../theme/types';
import { useCartHook } from '../../useCartHook';
interface IModal {
  isOpen: boolean;
  onClose: () => void;
  handleQuantityChange: (product: IProduct, newAmount: number) => void;
  product: IProduct | null;
}
interface IColor {
  active: boolean;
  theme: Itheme;
}

export const Modal = ({
  isOpen,
  onClose,
  handleQuantityChange,
  product,
}: IModal) => {
  const [isExiting, setIsExiting] = useState(false);
  const [updatedItemAmount, setUpdatedItemAmount] = useState(0);
  const [activeQuantity, setActiveQuantity] = useState(0);
  const { handleCart } = useCartHook();

  const closeModal = () => {
    setIsExiting(true);
    setTimeout(() => {
      setIsExiting(false);
      onClose();
    }, 800);
  };

  const handleQuantityChangeAndCloseModal = (
    product: IProduct,
    newAmount: number
  ) => {
    if (newAmount === 0) {
      handleCart('removeAll', product); // Assuming handleCart is a function to remove all items of the product from the cart
    } else {
      handleQuantityChange(product, newAmount);
    }

    closeModal();
  };

  return (
    <Drawer
      anchor="bottom"
      open={isOpen}
      onClose={closeModal}
      sx={{
        '& .MuiDrawer-paper': {
          width: '100%',
          maxWidth: '500px',
          borderRadius: '16px 16px 0 0',
        },
      }}
    >
      <ModalContainer>
        <ModalHeader>
          <p>Choose Quantity</p>
          <IoIosCloseCircleOutline
            onClick={closeModal}
            style={{ fontSize: '40px', cursor: 'pointer' }}
          />
        </ModalHeader>
        <ModalBody>
          {[...Array(11)].map((_, index) => (
            <ModalItem
              key={index}
              active={activeQuantity === index}
              onClick={() => {
                setUpdatedItemAmount(index);
                setActiveQuantity(index);
              }}
              theme={theme}
            >
              {index}
            </ModalItem>
          ))}
        </ModalBody>
        <ModalButton
          onClick={() =>
            product
              ? handleQuantityChangeAndCloseModal(product, updatedItemAmount)
              : undefined
          }
          color="primary"
        >
          Done
        </ModalButton>
      </ModalContainer>
    </Drawer>
  );
};

const ModalContainer = styled.div`
  padding: 16px;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${theme.target.grey};

  p {
    font-size: 16px;
    font-weight: 600;
  }
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  max-height: 150px;
  gap: 10px;
  z-index: 4000;
  padding: 40px 0px;
`;

const ModalItem = styled.p<{ active: boolean; theme: Itheme }>`
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  padding: 4px;
  color: ${({ active, theme }: IColor) =>
    active ? theme.target.black : theme.target.grey};
  background-color: ${({ active, theme }: IColor) =>
    active ? theme.target.greyVeryLight : 'inherit'};
`;

const ModalButton = styled.button`
  border: none;
  padding: 12px;
  width: 100%;
  margin-top: 24px;
  font-size: 20px;
  border-radius: 8px;
  margin-bottom: 8px;
  background-color: ${theme.target.primary};
  color: ${theme.target.white};
`;
