import React from 'react';
import { Code } from 'react-content-loader';
import { IProduct } from '../../types';
import { PdpGallery } from '../../components/PdpGallery';
import { PdpCard } from '../../components/PdpCard';
import { SimilarProducts } from '../../components/SimilarProducts';
import { usePdpRoute } from './usePdpRoute';
import { PdpRouteStyled } from './styles';
import { PdpRating } from '../../components/PdpRating';
import { PdpReviews } from '../../components/PdpReviews';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../hooks/useConfiguration';
import { IpdpField, PdpFieldName } from '../../types/config';
import { useQuery } from '@apollo/client';
import { GET_LOCALIZED } from '../../queries';
import { ExpantableComponent } from '../../components/ExpantableComponent';
import { LoadingSpinner } from '../../components/LoadingSpinnter';
import ProductDescription from '../../components/ProductDescription';

export const PdpRoute = () => {
  const {
    loading,
    categoryLoading,
    categoryData,
    data,
    categoryId,
    shuffledProducts,
  } = usePdpRoute();


  const { storeId = '', harvestId = '' } = useParams();

  const { PDPFields, LanguageVersion } = useConfiguration({
    storeId,
    harvestId,
  });

  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });

  if (loading || categoryLoading) {
    return <LoadingSpinner />;
  } else {
    const categoryEdges = categoryData?.getEPogTermListing?.edges;
    const fullpath =
      categoryEdges && categoryEdges[0] && categoryEdges[0]?.node?.fullpath;
    const breadcrumbs = fullpath?.split(`/${harvestId}`)[1];

    const product = data.getEPog as IProduct;
    return (
      <PdpRouteStyled>
        <div className="container">
          {categoryId && <div>{breadcrumbs}</div>}
          <div className="details">
            <PdpCard product={product} />
          </div>
        </div>
        <hr />
        {PDPFields &&
          Object.values(PDPFields).map((field: IpdpField, index: number) => {
            if (field.Name === PdpFieldName.Description) {
              return (
                <React.Fragment key={`description-${index}`}>
                  <div className="container">
                    <div className="description">
                      <ExpantableComponent
                        title={translation?.getLocalization.ProductDetails}
                      >
                        <ProductDescription content={product?.Description}/>
                      </ExpantableComponent>
                    </div>
                  </div>
                </React.Fragment>
              );
            }
            if (field.Name === PdpFieldName.Reviews) {
              return (
                <React.Fragment key={`reviews-${index}`}>
                  <div className="container">
                    <div className="reviews">
                      <h3>Review</h3>
                      <PdpRating product={product} />
                    </div>
                  </div>
                </React.Fragment>
              );
            }
            if (field.Name === PdpFieldName.ProductYouMakeLike) {
              return (
                <React.Fragment key={`productYouMakeLike-${index}`}>
                  <div className="container">
                    <h2>Frequently bought together</h2>
                    <SimilarProducts
                      className="productYouMakeLike"
                      products={shuffledProducts?.shuffledSimilar}
                    />
                  </div>
                  <hr />
                </React.Fragment>
              );
            }
            if (field.Name === PdpFieldName.Recommended) {
              return (
                <React.Fragment key={`recommended-${index}`}>
                  <div className="container">
                    <h2>Similar items</h2>
                    <SimilarProducts
                      className="recommended"
                      products={shuffledProducts?.shuffledRecommended}
                    />
                  </div>
                  <hr />
                </React.Fragment>
              );
            }
            return null;
          })}
      </PdpRouteStyled>
      // </Slide>
    );
  }
};
