import React, { useState } from 'react';
import styled from '@emotion/styled';
import { FilterModalCommonStyles } from '../commonStyles';
import { FilterModalButtons } from '../../FilterModalButtons';
import { useAppContext } from '../../../context';
import { actions } from '../../../context/actions';
import { CustomCheckbox } from '../../SucessCheckbox';
import { FormControlLabel, IconButton } from '@mui/material';
import { theme } from '../../../theme';
import { ModalTypes } from '../../Modals/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export const PriceRangeFilter = ({
  buttonsOff,
  productsBeforeFiltering,
  header,
  handleClose,
}: any) => {
  const {
    value: { searchFilters },
    dispatch,
  } = useAppContext();

  const priceFilter = searchFilters.find(
    (filter: any) => filter.name === 'Price'
  );
  const products = [...productsBeforeFiltering];
  products.sort((a, b) => b.RegularPrice - a.RegularPrice);

  const maxPrice = products[0]?.RegularPrice || 150;
  const minPrice = products[products.length - 1]?.RegularPrice || 0;

  const priceRanges = [
    {
      label: `$0 - $${Math.floor(maxPrice * 0.25)}`,
      min: 0,
      max: Math.floor(maxPrice * 0.25),
    },
    {
      label: `$${Math.floor(maxPrice * 0.25)} - $${Math.floor(maxPrice * 0.5)}`,
      min: Math.floor(maxPrice * 0.25),
      max: Math.floor(maxPrice * 0.5),
    },
    {
      label: `$${Math.floor(maxPrice * 0.5)} - $${Math.floor(maxPrice * 0.75)}`,
      min: Math.floor(maxPrice * 0.5),
      max: Math.floor(maxPrice * 0.75),
    },
    {
      label: `$${Math.floor(maxPrice * 0.75)} - $${maxPrice}`,
      min: Math.floor(maxPrice * 0.75),
      max: maxPrice,
    },
  ];

  const [selectedRange, setSelectedRange] = useState({
    min: priceFilter?.userSelectedPriceFilterMin || minPrice,
    max: priceFilter?.userSelectedPriceFilterMax || maxPrice,
  });

  const handleChange = (range: any) => {
    setSelectedRange((prev) => {
      const newRange =
        prev.min === range.min && prev.max === range.max
          ? { min: minPrice, max: maxPrice }
          : range;
      dispatch({
        type: actions.ADD_SEARCH_FILTER,
        payload: {
          name: 'Price',
          userSelectedPriceFilterMin: newRange.min,
          userSelectedPriceFilterMax: newRange.max,
          isFiltered: true,
        },
      });
      return newRange;
    });
  };

  const handleSubmit = () => {
    dispatch({
      type: actions.TOGGLE_MODAL,
      payload: {
        isModal: false,
        modalType: ModalTypes.filtersMenu,
      },
    });
    dispatch({
      type: actions.TOGGLE_MODAL,
      payload: {
        isModal: false,
        modalType: ModalTypes.priceRange,
      },
    });
  };

  const handleClearPriceFilter = () => {
    const recalculatedMinPrice =
      products[products.length - 1]?.RegularPrice || 0;
    const recalculatedMaxPrice = products[0]?.RegularPrice || 150;
    setSelectedRange({ min: recalculatedMinPrice, max: recalculatedMaxPrice });
    dispatch({
      type: actions.ADD_SEARCH_FILTER,
      payload: {
        name: 'Price',
        userSelectedPriceFilterMin: recalculatedMinPrice,
        userSelectedPriceFilterMax: recalculatedMaxPrice,
        isFiltered: true,
      },
    });
  };

  return (
    <FilterModalCommonStyles buttonsOff={buttonsOff}>
      {header && (
        <HeaderStyled>
          <span>Price</span>
          <IconButtonStyled onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </IconButtonStyled>
        </HeaderStyled>
      )}
      <PriceRangeStyled>
        <div className="price-container">
          {priceRanges.map((range, index) => (
            <StyledFormControlLabel
              key={index}
              control={
                <CustomCheckbox
                  key={index}
                  name={`price-range-${index}`}
                  checked={
                    selectedRange.min === range.min &&
                    selectedRange.max === range.max
                  }
                  onChange={() => handleChange(range)}
                />
              }
              label={range.label}
            />
          ))}
        </div>
        <ButtonsContainer>
          <FilterModalButtons
            onSubmit={handleSubmit}
            onClear={handleClearPriceFilter}
          />
        </ButtonsContainer>
      </PriceRangeStyled>
    </FilterModalCommonStyles>
  );
};

const PriceRangeStyled = styled.div`
  position: relative;
  height: 83vh;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;

  .price-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
    width: 100%;
    height: 100vh;
  }
  .MuiFormControlLabel-label {
    font-size: 20px;
  }
`;
export const IconButtonStyled = styled(IconButton)`
  & > svg {
    color: ${theme.target.greyDark};
  }
`;
const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 700;
  border-bottom: 1px solid ${theme.target.grey};
`;
const StyledFormControlLabel = styled(FormControlLabel)`
  width: 100%;
  padding: 20px 0;
  margin: 0;
  border-bottom: 1px solid ${theme.target.grey};
`;
export const ButtonsContainer = styled.div`
  background-color: #fff;
  padding: 16px;
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 70px;
  z-index: 1000;
`;
