import { useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { theme } from '../../theme';
interface ICategory {
  key: string;
  id: string;
  fullpath: string;
}

export const PopularSections = ({
  categories,
}: {
  CarouselWidth?: string;
  categories?: { node: ICategory }[];
}) => {
  const navigate = useNavigate();
  const { storeId = '', harvestId = '' } = useParams();

  if (!categories || categories.length === 0) {
    return null;
  }

  return (
    <StyledWrapper>
      {categories?.map(({ node: { key, id } }, index) => (
        <div
          onClick={() =>
            navigate(
              `/${storeId}/${harvestId}/category/${id}__${key.replaceAll(
                ' ',
                '_'
              )}`
            )
          }
          key={index}
          style={{
            flex: '0 0 auto',
            padding: '0 10px',
          }}
        >
          <p
            style={{
              fontSize: '14px',
              fontWeight: 600,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              marginLeft: '8px',
            }}
          >
            {key}
          </p>
        </div>
      ))}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  margin-top: 90px;
  height: 60px;
  background-color: ${theme.target.red?.dark};
  color: ${theme.target.white};

  ::-webkit-scrollbar {
    display: none;
  }
`;
