import styled from '@emotion/styled';
import { theme } from '../../theme';

export const PdpRouteStyled = styled.div`
  .details {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  & > h2:nth-of-type(1) {
    margin-top: 96px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: ${theme.target.black};

    @media (max-width: 768px) {
      margin-top: 30px;
    }
  }

  div > h2 {
    margin-top: 96px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: ${theme.target.black};
    margin-bottom: 24px;
    padding: 0 10px;

    @media (max-width: 768px) {
      margin-top: 30px;
    }
  }

  h2:nth-of-type(2) {
    margin: 96px 0 24px 0px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: ${theme.target.black};
  }

  h3:nth-of-type(1) {
    margin: 48px 0 35px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: ${theme.target.black};

    @media (max-width: 768px) {
      margin: 10px 0 ;
    }
  }

  h3:nth-of-type(2) {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: ${theme.target.black};
    margin-bottom: 24px;
  }

  h3:nth-of-type(3) {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: ${theme.target.black};
    margin-bottom: 16px;
  }

  & > div > p {
    @media (max-width: 768px) {
    max-width: 647px;
    }

    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${theme.target.black};
    margin-bottom: 21px;
  }

  hr:nth-of-type(1) {
    margin: 16px 0;
  }

  hr:nth-of-type(2) {
    margin: 16px 0 16px;
  }

  @media (max-width: 768px) {
    hr {
      margin: 10px 0 ;
    }
  }

  .description {
    padding-bottom: 40px;
    & > h3:nth-of-type(1) {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: ${theme.target.black};
    }

    & > h3:nth-of-type(2) {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: ${theme.target.black};
    }

    & > p {
      @media (max-width: 768px) {
      max-width: 744px;
      }

      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: ${theme.target.black};
      margin-bottom: 32px;
    }

    & > div {
      @media (max-width: 768px) {
      max-width: 744px;
      }

      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: ${theme.target.black};
    }
  }

  .reviews {
    max-width: 743px;
    padding: 0 10px;
    padding-bottom: 60px;
  }

  .specifications {
    margin-bottom: 32px;

    & > h3:nth-of-type(1) {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: ${theme.target.black};
    }

    & > h3:nth-of-type(2) {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: ${theme.target.black};
    }

    & > div {
      @media (max-width: 768px) {
      max-width: 744px;
      }

      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: ${theme.target.black};
      margin-left: 16px;
    }
  }
  .additional-tab {
    & > h3 {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: ${theme.target.black};
    }

    & > p {
      @media (max-width: 768px) {
      max-width: 744px;
      }

      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: ${theme.target.black};
    }
  }
`;
