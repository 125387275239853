import { RiPriceTag3Fill } from 'react-icons/ri';
import { theme } from '../../theme';
import styled from '@emotion/styled';
interface IProps {
  scale?: string;
}
export const PriceTagIcon = ({ scale }: IProps) => (
  <div style={{ scale: scale }}>
    <PriceTagStyled>
      <RiPriceTag3Fill />
    </PriceTagStyled>
  </div>
);

const PriceTagStyled = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 15px;
  height: 15px;
  border: 2px solid ${theme.target.black};
  border-radius: 50%;
  margin: auto;
  border-color: ${theme.target.turquoise};
  & > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-47%, -47%) scaleX(-1);
    width: 80%;
    height: auto;
    color: ${theme.target.red?.dark};
  }
`;
