import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import { theme } from '../../theme';

export const PdpCardStyled = styled.div`
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 24px 10px 0;
  grid-column: 3 / span 1;
  margin-top: 70px;
  width: 100%;

  .stickyAddToCart {
    position: fixed;
    bottom: 70px;
    left: 0;
    width: 100%;
    z-index: 1000;
    background: ${theme.target.white};
    padding: 10px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  }
  & > p:nth-of-type(1) {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: ${theme.target.black};
    margin: 10px 0;
  }
  .price-container {
    margin-top: 60px;
    .price {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: ${theme.target.black};
      & > span {
        color: #777;
        font-weight: 400;
      }
    }
    & > span {
      margin-top: 10px;
      color: gray;
    }
  }
  .location-container {
    display: flex;
    align-items: center;
    font-size: 16px;
    padding: 10px 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: white;
    margin-top: 10px;
    svg {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
    p {
      margin: 0;
    }
    span {
      color: ${theme.target.greyDark};
      font-size: 12px;
    }
  }
  .pdp-gallery {
    width: 100%;
    margin-bottom: 30px;
    position: relative;

    .heart-icon-container {
      position: absolute;
      width: 40px;
      height: 40px;
      bottom: 5px;
      right: 5px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 24px;
      svg {
        color: ${theme.target.footerFontColor};
      }
    }
  }

  .addToCartPDPButton {
    margin: 15px 0 15px 0;
    float: right;
  }

  .oldPrice {
    font-size: 15px;
    color: ${theme.target.greyDark};
    text-decoration: line-through;
  }

  .attributes {
    font-weight: 500;
    font-size: 12px;
    color: ${theme.target.black};
    margin: 10px 0;
    & > span {
      color: ${theme.target.footerFontColor};
    }
    .deal-content {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 6px;
    }

    .deal-icon {
      margin-left: 10px;
      margin-right: 20px;
    }

    .deal-content p {
      flex: 1;
      font-weight: 600;
      font-size: 16px;
    }
    .deal-content div > p > span {
      display: block;
      color: ${theme.target.footerFontColor};
      font-size: 10px;
      font-weight: normal;
      margin-top: 4px;
    }
  }

  .shipping-methods {
    .container {
      display: flex;
    }
  }

  .brand-line {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p:nth-of-type(1) {
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      text-decoration-line: underline;
      color: ${theme.target.black};
      display: inline-block;
    }

    & > div {
      display: flex;
      align-items: center;
    }
  }

  & > p:nth-of-type(2) {
    margin-bottom: 9.33px;
  }

  & > p:nth-of-type(4) {
    margin: 12px 0;
  }

  & > hr {
    margin: 12px 0;
  }

  & > p > span {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: ${theme.target.black};
    margin-bottom: 24px;
  }

  .rating {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    & > span {
      margin-left: 5px;
    }
  }
  .cart {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: ${theme.target.white};
    padding: 16px;
    border-radius: 4px;
    margin: 10px 0;

    .addToCartButton {
      display: flex;
      width: 100%;
    }

    .cart-manipulation {
      width: 30%;
      & > span {
        margin: 0 10px;
        @media (max-width: 991px) {
          margin: 0 3px;
        }
      }
    }

    & > button {
      width: 100%;
    }
  }
  .listButton-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 10px;
    padding: 10px;
    margin: 10px 0;
    background: ${theme.target.white};
    font-size: 12px;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;
export const StickyAddToCart = styled.div`
  position: 'fixed';
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background: white;
  padding: 10px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
`;
export const ButtonInCart = styled(Button)`
  color: ${theme.target.green};
  background: ${theme.target.white};
  padding: 9px 16px;
  border: 1px solid ${theme.target.green};
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  width: 100%;
  text-transform: capitalize;
  display: flex;
  align-items: center;

  .faIcon {
    color: ${theme.target.greyDark};
    margin-left: 8px;
  }
  &:hover {
    background: transparent;
  }
`;
export const ListButton = styled(Button)`
  border-radius: 4px;
  border: 1px solid ${theme.target.greyDark};
  background-color: transparent;
  color: ${theme.target.greyDark};
  width: 50%;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 12px;

  &:hover {
    background-color: ${theme.target.greyLight};
  }
`;

export const UsualsButton = styled(Button)`
  border-radius: 4px;
  border: 1px solid ${theme.target.footerFontColor};
  background-color: transparent;
  margin-right: 8px;
  color: ${theme.target.greyDark};
  width: 50%;
  text-transform: capitalize;
  font-size: 12px;
  &:hover {
    background-color: ${theme.target.greyLight};
  }
`;

export const AddButton = styled.button`
  background: transparent;
  border: none;
  text-decoration: underline;
`;

export const ButtonStyled = styled(Button)`
  color: ${theme.target.white};
  background: ${theme.target.primary};
  padding: 9px 16px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  width: 100%;
  text-transform: capitalize;
  &:hover {
    background: ${theme.target.primary};
  }
`;
export const ShippingMethodStyled = styled.button<{
  isActive?: boolean;
}>`
  &:hover {
    cursor: pointer;
  }
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-right: 5px;
  border: 1px solid ${theme.target.grey};
  cursor: pointer;
  border-radius: 5px;
  background-color: ${({ isActive }) =>
    isActive ? `${theme.target.greyLight}` : `${theme.target.white}`};
  box-shadow: ${({ isActive }: any) =>
    isActive
      ? `0 0 0 0.125rem ${theme.target.green}`
      : `0 0 0 0.0625rem ${theme.target.white}`};
  border-radius: 5px;
  width: 250px;

  &:hover {
    background-color: ${theme.target.greyLight};
  }
  .icon {
    font-size: 24px;
    margin: 10px 0;
  }

  .name {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 16px;
  }
  svg {
    color: ${theme.target.primary};
  }
  .description {
    text-align: left;
    font-size: 12px;
    color: ${({ isActive }) =>
      isActive ? `${theme.target.green}` : `${theme.target.greyDark}`};
    white-space: normal;
    word-wrap: break-word;
  }
`;
