import { ApolloProvider } from '@apollo/client';
import { Global } from '@emotion/react';
import { GlobalStylesGeneric } from './global-styles/GlobalStyle';
import { graphqlClient } from './config/graphql-client';
import GENERIC from './generic';

const Application = () => {
  return (
    <ApolloProvider client={graphqlClient}>
      <App />
    </ApolloProvider>
  );
};

const App = () => {
  return (
    <>
      <Global styles={GlobalStylesGeneric} />
      <GENERIC />
    </>
  );
};

export default Application;
